import React, {Fragment, useState} from "react";
import "./right-section.scss";
// import Modal from "../../Common/Modal";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {MODALS} from '../../../storeManager/modal/constants';
import {openModal as openModalHandler} from "../../../storeManager/modal/actions";
import {dateFormatter, numberFormatter} from '../../../helper';
import { withRouter } from "react-router-dom";
import {PATHS, MESSAGES} from "../../../constants";

const mapDispatchToProps = dispatch => ({
    openModal: bindActionCreators(openModalHandler, dispatch),
});

const DetailRightSection = (props) => {
    const { activityList, appliedPartnershipDetails, openModal, allActions, responseObject, history, resetPage, 
        // totalDocuments, 
        pendingDocuments } = props;
    // const partnerDocument = responseObject && responseObject.partnerDocument;
    const dateFormat = 'dd/MMM/yyyy';

    const getButtonSection = (allActions) => {
        if (allActions) {
            let ButtonElem = [], openFormLen = 0, filteredAllAction = [], isApprovedButton = false;
            allActions.forEach((button, i) => {
                if (button.type === 'OPEN_FORM') {
                    openFormLen += 1;
                } else if (button.type === 'APPROVE_DOCUMENT' || button.type === 'APPROVE_DOCUMENT_APPLICATION') {
                    filteredAllAction.push(button);
                    if(!isApprovedButton) {
                        isApprovedButton = true;
                        const modalName = `APPROVE_DOCUMENT_MODAL`;
                        ButtonElem.unshift((<button className={`button full big mb10 ${button.priority === 'PRIMARY' ? 'action' : 'fill'}`} key="open_form"
                                                 onClick={() => button.reassignPopUpVisible === false ? openModal(
                                                     MODALS[modalName],
                                                     {
                                                         hideCloseButton: false,
                                                         customModalClass: "overlay",
                                                         customModalContentClass: "right-view-modal",
                                                         buttonInfo: button,
                                                         responseObject: responseObject,
                                                         filteredAllAction: filteredAllAction,
                                                         resetPage: resetPage
                                                     }
                                                 ): openModal(MODALS.CONFIRMATION_POPUP, {
                                                    message: MESSAGES.STOP_ACTION
                                                })}
                            >
                                Review Document
                            </button>)
                        )
                    }
                } else {
                    if (button.type === 'PARTNER_FORM_CREATION'){
                        const modalName = `${button.type}_MODAL`;
                        ButtonElem.push((<button className={`button full big mb10 ${button.priority === 'PRIMARY' ? 'action' : 'fill'}`} key={i}
                            onClick={() => button.reassignPopUpVisible === false ? openModal(
                                MODALS[modalName],
                                {
                                    hideCloseButton: false,
                                    customModalClass: "overlay partner-creation-overlay",
                                    customModalContentClass: "Partner-creation-form",
                                    buttonInfo: button,
                                    responseObject: responseObject,
                                    resetPage: resetPage
                                }
                            ) : openModal(MODALS.CONFIRMATION_POPUP, {
                                message: MESSAGES.STOP_ACTION
                            })}
                        >
                            {button.label || ''}
                        </button>))
                    }
                    else{
                        const modalName = `${button.type}_MODAL`;
                        ButtonElem.push((<button className={`button full big mb10 ${button.priority === 'PRIMARY' ? 'action' : 'fill'}`} key={i}
                            onClick={() => button.reassignPopUpVisible === false ? openModal(
                                MODALS[modalName],
                                {
                                    hideCloseButton: false,
                                    customModalClass: "overlay",
                                    customModalContentClass: "right-view-modal",
                                    buttonInfo: button,
                                    responseObject: responseObject,
                                    resetPage: resetPage
                                }
                            ) : openModal(MODALS.CONFIRMATION_POPUP, {
                                message: MESSAGES.STOP_ACTION
                            })}
                        >
                            {button.label || ''}
                        </button>))
                    }
                    
                }
            });
            if (openFormLen > 0) {
                ButtonElem.unshift((<button className={`button full big mb10 fill`} key="open_form"
                    onClick={() => history.push(`${PATHS.APPLICATION_FORM}/${responseObject.id || ''}`)}
                >
                    Review
                </button>))
            }
            return ButtonElem
        }
        return false
    }
    const [isActionsActive, setActionStatus] = useState('');
    const setActions = () => {
        setActionStatus(!isActionsActive);
    };

    return (
        <Fragment>
            {pendingDocuments && <div className="pending-doc-section">
            <span className="label">Pending Documents : </span>
                <span className="value">{pendingDocuments}</span>
                {/* <span className="label"> &nbsp;/ {totalDocuments}</span> */}
            </div>}
            <div className={`button-list-wrapper ${isActionsActive && 'active'}`}>
                <span className="close-popup" onClick={setActions}>
                    Close
                    <svg width="15" height="15">
                        <path fill="#484848" fillRule="evenodd" d="M14.51 13.064l-1.402 1.447-5.393-5.564-.215.222-.216-.222-5.393 5.563-1.402-1.447L5.882 7.5.489 1.937 1.891.49l5.393 5.563.216-.223.215.223L13.108.489l1.402 1.447L9.117 7.5l5.393 5.564z" />
                    </svg>
                </span>
                {getButtonSection(allActions)}
            </div>
            {
                getButtonSection(allActions).length > 0 ? (
                    <div className={`take-action ${isActionsActive && 'active'}`} onClick={setActions}>
                        <span>Take Action</span>
                    </div>
                ):null
            }
            {activityList && activityList.length > 0 && (
                <div className="activity-list-wrapper">
                    <h4 className="title"> Activity ({activityList.length})</h4>
                    {activityList.map((item, index) => (
                        <div className="activity-item" key={index}>
                            <div className="date">
                                {item.date && dateFormatter(item.date, dateFormat)} |{" "}
                                <span className="assigned-by">{item.assignedBy}</span>
                            </div>
                            <div className="assigned-to">
                                Assigned {item.isAssignedBack && "back"} to {item.assignedTo}{" "}
                                {/* {item.isRejected && (
                                    <span className="rejected">- {item.actionName}</span>
                                )} */}

                                - <span className="rejected"> {item.actionName}</span>
                            </div>
                            {item.comments && (
                                <div className="comments">"{item.comments}"</div>
                            )}
                        </div>
                    ))}
                </div>
            )}

            {
                appliedPartnershipDetails && appliedPartnershipDetails.channel && (
                    <div className="partner-details-wrapper">
                        <div className="title">TATA PLAY APPLICANT DETAILS</div>
                        <div className="partner-detail-item">
                            <div className="label">
                                Applicant Type
                            </div>
                            <div className="value">
                                {(appliedPartnershipDetails.channel || {}).name}
                            </div>

                            <div className="label">
                                Territory Covered
                            </div>
                            <div className="value">
                                {appliedPartnershipDetails.marketAreaCovered || 0} Sq Km
                            </div>

                            <div className="label">
                                Expected Turnover
                            </div>
                            <div className="value">
                                {numberFormatter(appliedPartnershipDetails.turnover) || 0}  (Rs)
                            </div>
                        </div>
                    </div>
                )
            }
            {/*<Modal />*/}
        </Fragment>
    );
};

export default connect(null, mapDispatchToProps)(withRouter(DetailRightSection));

