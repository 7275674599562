import React, {Component, Fragment} from "react";
import GlobalConfig from "../../../globalConfig/globalConfig";
import {commonService} from "../../../storeManager/common/services";
import Input from "../../Common/Input";
import FormValidator from '../../../services/FormValidator';
import './login.scss';
import '../../../assets/stylesheet/component/_input-element.scss';
import Loader from "./../../Loader";
import {isAuthenticated} from "../../../helper";
import {PATHS} from "../../../constants";
import {withRouter} from 'react-router-dom';


class Login extends Component {
    state = {
        loginForm: {
            mobileNumber: {
                wrapperClasses: ['form-element'],
                inputGroupClasses: ['icon', 'phone'],
                labelConfig: {
                    label: 'Mobile Number',
                    classes: ['floating-label'],
                },
                elementType: 'input',
                elementConfig: {
                    type: 'tel',
                    placeholder: 'Mobile Number',
                    maxLength: 10,
                    tabIndex: 0,
                },
                value: '',
                errorMessage: '',
                valid: true,
                touched: false,
            },
        },
        error: '',
        isLoading: false
    };

    inputChangedHandler = (inputValue, inputIdentifier) => {
        const {loginForm} = this.state;
        const updatedForm = {...loginForm};
        const updatedFormElement = {...updatedForm[inputIdentifier]};
        let value = inputValue;
        if(inputIdentifier === 'mobileNumber') {
            value = value.replace(/[^0-9]/g, '');
        }
        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = true;
        updatedFormElement.errorMessage = '';
        updatedForm[inputIdentifier] = updatedFormElement;
        this.setState({loginForm: updatedForm, error: ''})
    };

    checkMobileNumber = (e) => {
        e.preventDefault();
        const { loginForm } = this.state;
        const validateObj = {};
        const formKeys = Object.keys(loginForm);
        formKeys.forEach((key) => {
            validateObj[key] = loginForm[key].value;
        });
        const formStatus = FormValidator.validateLoginForm(validateObj);
        const { hasErrors, ...errorMessages } = formStatus;
        if (hasErrors) {
            const updatedForm = { ...loginForm };
            formKeys.forEach((key) => {
                const inputKey = `${key}Message`;
                const errorMessage = errorMessages[inputKey];
                if (errorMessage && errorMessage !== '') {
                    updatedForm[key].valid = false;
                    updatedForm[key].errorMessage = errorMessage;
                }
            });
            this.setState({ loginForm: updatedForm, formErrorMessage: '' });
        } else {
            this.validateMobileFromServer(validateObj);
        }
    };

    validateMobileFromServer = async (validateObj) => {
        const {openOTP} = this.props;
        const mobileNumber = validateObj.mobileNumber;
        const payload = {
            mobileNumber: mobileNumber,
            service: "LOGIN"
        };
        this.setState({isLoading: true});
        if(!isAuthenticated()) {
            try {
                const response = await commonService.generateOtp(payload);
                const responseData = response.data;
                if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                    this.setState({ isLoading: false });
                    openOTP(mobileNumber);
                }
                else {
                    this.setState({
                        error: responseData.message,
                        isLoading: false
                    })
                }
            } catch {
                this.setState({ error: GlobalConfig.serverError, isLoading: false })
            }
        } else {
            const { history } = this.props;
            history.push(PATHS.HOME);
        }
    };

    render() {
        const {loginForm, error, isLoading} = this.state;
        const {checkMobileNumber} = this;
        let formElementsArray = [];
        const formKeys = Object.keys(loginForm);
        formKeys.forEach((key) => {
            formElementsArray.push({
                id: key,
                config: loginForm[key],
            });
        });
        return (
            <Fragment>
                {
                    isLoading && <Loader/>
                }
                <form onSubmit={checkMobileNumber}>
                    {
                        formElementsArray && formElementsArray.map(formElement => (
                            <Input
                                key={formElement.id}
                                labelConfig={formElement.config.labelConfig}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                valid={formElement.config.valid}
                                errorMessage={formElement.config.errorMessage}
                                touched={formElement.config.touched}
                                wrapperClasses={formElement.config.wrapperClasses}
                                inputGroupClasses={formElement.config.inputGroupClasses}
                                changed={event => this.inputChangedHandler(event, formElement.id)}
                            />
                        ))
                    }
                        {
                            error && <div className="server-msg">{error}</div>
                        }
                    <button className="button fill full big mt10 mb40" type="submit">
                        next
                    </button>
                </form>
            </Fragment>

        )
    }
}

export default withRouter(Login);