import React, { useState, useEffect, useRef } from 'react';
import Search from '../Search';
import _debounce from 'lodash/debounce';
import _isEmpty from 'lodash/isEmpty';
import _replace from 'lodash/replace'
import { PATHS } from '../../../constants'
import { useHistory } from 'react-router-dom';
import loadingImg from '../../../assets/images/Rolling.gif'
import GlobalConfig from "../../../globalConfig/globalConfig";
import { commonService } from "../../../storeManager/common/services";
import './style.scss';

const GlobalSearch = (props) => {
    const history = useHistory()
    const [searchResult, setSearchResult] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [searchClass, setSearchClass] = useState('');
    const [offset, setOffset] = useState(0);
    const [isLoader, setIsLoader] = useState(true);
    const [totalResults, setTotalResults] = useState(0);
    const [clearSearch, setClearSearch] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setSearchClass('')
                setInputValue('')
                setSearchResult([])
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [wrapperRef]);
    const getProfileList = async (inputValue, offset = 0, newSearch = true) => {
        setIsLoader(true)
        const payload = {
            "offset": offset,
            "searchText": inputValue,
            "sortType": "",
            "createdDate": {
                "from": "",
                "to": ""
            },
            "status": "",
            "limits": 10
        }
        try {
            const response = await commonService.dashBoardList(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                const allProfileList = responseData.data.listDTO;
                setTotalResults(responseData.data.total)
                setOffset(offset + 1);
                let newSearchResult = [];
                for (let n in allProfileList) {
                    newSearchResult.push({
                        applicationFormId: allProfileList[n].applicationFormId,
                        resultString: generateResultString(allProfileList[n], convertString(inputValue))
                    })
                }
                if (newSearch)
                    setSearchResult(newSearchResult)
                else
                    setSearchResult([...searchResult, ...newSearchResult])
                setIsLoader(false)
            } else {
                // this.setState({ error: responseData.message});
                setIsLoader(false)
            }
        } catch (error) {
            // this.setState({ error: GlobalConfig.serverError});
            setIsLoader(false)
        }
    }

    const handleResultClick = (requestId, e) => {
        console.log("requestId", requestId)
        history.push(PATHS.DETAIL, {
            requestId: requestId
        });
        setSearchClass('')
        window.location.reload();
    }

    const generateHtmlString = (resStr, searchStr) => {
        const matchIndex = convertString(resStr).indexOf(searchStr);
        // const subStr = resStr.split('').slice(matchIndex, searchStr?.length).join('');
        const subStr = [];
        for (let i = matchIndex; i < matchIndex + searchStr?.length; i++) {
            subStr.push(resStr[i])
        }
        const newSubStr = subStr.join('')
        let strRegExp = new RegExp(newSubStr, 'g');
        return _replace(resStr, strRegExp, `<b>${newSubStr}</b>`);
    }

    const generateResultString = (resultObj, searchStr) => {
        let displayId = resultObj.displayId;
        let firmName = resultObj.firmName;
        if (convertString(resultObj?.displayId).includes(searchStr)) {
            displayId = generateHtmlString(resultObj?.displayId, searchStr)
        }
        if (convertString(resultObj?.firmName).includes(searchStr)) {
            firmName = generateHtmlString(resultObj?.firmName, searchStr)
        }
        return `${displayId} - ${firmName}`
    }

    const convertString = str => str.trim().toLowerCase()
    const onChangeHandler = value => {
        let SearchValue = value.trimStart()
        setInputValue(SearchValue)
        onChangeSearchHandler(SearchValue)
    }
    const onChangeSearchHandler = _debounce((inputValue) => {
        if (!_isEmpty(inputValue) && inputValue.length > 2) {
            setIsLoader(true)
            getProfileList(inputValue, 0, true)
        }
        if (inputValue.length > 2) {
            _isEmpty(searchClass) && setSearchClass("open-search-suggestion");
        }
        else {
            setSearchClass("");
        }
    }, 300)

    return (
        <div className={`globalSearchWrap ${searchClass}`} ref={wrapperRef}>
            {/*<Search onChangeSearchHandler={onChangeSearchHandler} allowSpecialChar={true}/>*/}
            <div className={`search-container`}>
                <div className="form-element">
                    <input
                        type="text"
                        className="search-input"
                        placeholder=''
                        name="searchValue"
                        value={inputValue}
                        maxLength={24}
                        autoComplete="off"
                        onChange={(e) => onChangeHandler(e.target.value)}
                    />
                </div>
            </div>
            <div className='searchSuggestions'>
                {searchResult && (inputValue.length > 2) &&
                    <ul>
                        {
                            searchResult.map((el) =>
                                <li
                                    key={el.applicationFormId}
                                    onClick={(e) => handleResultClick(el.applicationFormId, e)}
                                    dangerouslySetInnerHTML={{ __html: el.resultString }}
                                />
                            )
                        }
                    </ul>
                }
                {isLoader ?
                    <div className='loader-bar-wrap'>
                        <img width="20" src={loadingImg} alt="search result loading" />
                    </div>
                    :
                    totalResults > searchResult.length &&
                    <span onClick={() => getProfileList(inputValue, offset, false)} className='loadMoreResult'>Load More</span>
                }
            </div>
            {_isEmpty(searchResult) && (inputValue.length > 2) && !isLoader &&
                <div className='no-result-found'>
                    <h3>No Result Found</h3>
                </div>
            }
        </div>
    )
}

export default GlobalSearch;