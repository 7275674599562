import { INPUT_MAX_LENGTH } from "../../../../constants";
export const PARTNER_CREATION_FIELDS = () => ({
    't':{
        wrapperClasses: ["form-element input-element grey"],//textarea-field
        inputGroupClasses:[],
        labelConfig: {
            label: "T",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0,
        },
        value: "0001",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass:'col-sm-3',
        page:1,
    },
    'companyCode': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Company Code",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "TSKY",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 1
    },
    'salesOrg': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Sales org",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Sales org ",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "TSSO",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 1
    },
    'division': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Division",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Division",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "TK",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 1
    },
    'distributionChannel': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Distribution Channel",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Distribution Channel",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "D1",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 1
    },
    'title': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Title",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Title",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "Company",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 1
    },
    'searchTerm': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Search Term",
            classes: ["label-title required-symbol"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Search Term",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTY,
            tabIndex: 0
        },
        value: "PC",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 1,
        validation: 'required'
    },
    'name': {
        wrapperClasses: ["form-element input-element blue"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Name",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Name",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTY,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-12',
        page: 1,
        validation: 'validateFirmName'
    },
    'streetHouseNumber': {
        wrapperClasses: ["form-element textarea-field blue"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Street/House number",
            classes: ["label-title"]
        },
        elementType: "textarea",
        elementConfig: {
            placeholder: "Enter Street/House number",
            maxLength: INPUT_MAX_LENGTH.LENGTH_ONE_FIFTY,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-12',
        page: 1,
        validation: 'validateAddress'
    },
    'postalCode': {
        wrapperClasses: ["form-element input-element blue"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Postal code",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Postal code",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 1,
        validation: 'validatePinCode'
    },
    'city': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "City",
            classes: ["label-title required-symbol"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter City",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTY,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 1,
        validation: 'required'
    },
    'country': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Country",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Country",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "IN",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 1
    },
    'region': {
        wrapperClasses: ["form-element select-control"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Region",
            classes: ["label-title required-symbol"]
        },
        elementType: "select",
        elementConfig: {
            placeholder: "",
            tabIndex: 0,
            options: [{ label: "Select", value: "", points: "", disabledFields: "" }],
            disabled: false,
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 1,
        validation: 'required'
    },
    'transportationZone': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Transportation zone",
            classes: ["label-title required-symbol"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Transportation zone",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTY,
            tabIndex: 0
        },
        value: "GJANAN",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 1,
        validation: 'required'
    },
    'mobilePhone': {
        wrapperClasses: ["form-element input-element blue"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Mobile Phone",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Mobile Phone",
            maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 1,
        validation: 'validatePhoneNumber'
    },
    'faxNumber': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Fax Number",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Fax Number",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 1
    },
    'email': {
        wrapperClasses: ["form-element input-element blue"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Email",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Email",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTY,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 1,
        validation: 'validateEmail'
    },
    'distributorChannelType': {
        wrapperClasses: ["form-element select-control"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Distributor channel type",
            classes: ["label-title required-symbol"]
        },
        elementType: "select",
        elementConfig: {
            placeholder: "",
            tabIndex: 0,
            options: [{ label: "Select", value: "", points: "", disabledFields: "" }],
            disabled: false,
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 1,
        validation: 'required'
    },
    // page 2 form elements
    'taxNumber': {
        wrapperClasses: ["form-element input-element blue"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Tax Number",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Tax Number",
            maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 2,
        validation: 'validatePanNumber'
    },
    'gst': {
        wrapperClasses: ["form-element input-element blue"],
        inputGroupClasses: [],
        labelConfig: {
            label: "GST",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter GST",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 2
    },
    'vatRegNo': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "VAT Reg  No",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter VAT Reg  No",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 2
    },
    'accountingClerk': {
        wrapperClasses: ["form-element select-control"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Accounting clerk",
            classes: ["label-title required-symbol"]
        },
        elementType: "select",
        elementConfig: {
            placeholder: "",
            tabIndex: 0,
            options: [{ label: "Select", value: "", points: "", disabledFields: "" }],
            disabled: false,
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 2,
        validation: 'required'
    },
    'reconciliationAccountInGeneralLedger': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Reconciliation Account in General Ledger",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Reconciliation Account in General Ledger",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "15000000",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 2
    },
    'salesOffice': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Sales Office",
            classes: ["label-title required-symbol"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Sales Office",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIVE,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 2,
        validation: 'required'
    },
    'salesDistrict': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Sales district",
            classes: ["label-title required-symbol"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Sales district",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIVE,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 2,
        validation: 'required'
    },
    'planningGroup': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Planning group",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Planning group",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "BA",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 2
    },
    'termsOfPayment': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Terms of Payment Key",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Terms of Payment Key",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "0001",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 2
    },
    'salesGroup': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Sales Group",
            classes: ["label-title required-symbol"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Sales Group",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIVE,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 2,
        validation: 'required'
    },
    'customerGroup': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Customer group",
            classes: ["label-title required-symbol"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Customer group",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 2,
        validation: 'required'
    },
    'toleranceGroupForBusinessPartner': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Tolerance group for the business partner/G/L account",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Tolerance group for the business partner/G/L account",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "TSKY",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-8',
        page: 2
    },
    'deliveryPriority': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Delivery Priority",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Delivery Priority",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "01",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 2
    },
    'shippingConditions': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Shipping Conditions",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Shipping Conditions",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "01",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 2
    },
    'incotermsPart1': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Incoterms (Part 1)",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Incoterms (Part 1)",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "FOB",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 2
    },
    'incotermsPart2': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Incoterms (Part 2)",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Incoterms (Part 2)",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "Free On Board",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 2
    },
    'termsOfPaymentKey': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Terms of Payment Key",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Terms of Payment Key",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "0001",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 2
    },
    'accountAssignmentGroupForThisCustomer': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Account assignment group for this customer",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Account assignment group for this customer",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "01",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-8',
        page: 2
    },
    'customerGroup1': {
        wrapperClasses: ["form-element select-control"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Customer group 1",
            classes: ["label-title required-symbol"]
        },
        elementType: "select",
        elementConfig: {
            placeholder: "",
            tabIndex: 0,
            options: [{ label: "Select", value: "", points: "", disabledFields: "" }],
            disabled: false,
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 2,
        validation: 'required'
    },
    'customerGroup2': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Customer group 2",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Customer group 2",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 2
    },
    'customerGroup3': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Customer group 3",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Customer group 3",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-4',
        page: 2
    },
    'keyForSortingAccordingToAssignmentNumbers': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Key for sorting according to assignment numbers",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Key for sorting according to assignment numbers",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "031",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-8',
        page: 2
    },
    //page 3
    'indicatorForWithholdingTaxType1': {
        wrapperClasses: ["form-element select-control"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Indicator for withholding tax type",
            classes: ["label-title required-symbol"]
        },
        elementType: "select",
        elementConfig: {
            placeholder: "",
            tabIndex: 0,
            options: [{ label: "Select", value: "", points: "", disabledFields: "" }],
            disabled: false,
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 3,
        validation: 'required'
    },
    'withholdingTaxCode1': {
        wrapperClasses: ["form-element select-control"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Withholding tax code",
            classes: ["label-title required-symbol"]
        },
        elementType: "select",
        elementConfig: {
            placeholder: "",
            tabIndex: 0,
            options: [{ label: "Select", value: "", points: "", disabledFields: "" }],
            disabled: false,
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 3,
        validation: 'required'
    },
    'indicatorWithholdingTaxAgent1': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Indicator: Withholding tax agent?",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Indicator: Withholding tax agent?",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "X",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 3
    },
    'withholdingTaxIdentificationNumber1': {
        wrapperClasses: ["form-element input-element blue"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Withholding tax identification number",
            classes: ["label-title required-symbol"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Withholding tax identification number",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 3,
        validation: 'required'
    },
    'obligatedToWithholdTaxFrom1': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Obligated to withhold tax from",
            classes: ["label-title required-symbol"]
        },
        elementType: "input",
        elementConfig: {
            type: "date",
            placeholder: "Obligated to withhold tax from",
            // maxLength: INPUT_MAX_LENGTH.LENGTH_HUNDRED,
            tabIndex: 0,
            disabled: false,
            //min: new Date().toISOString().slice(0, 10),
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 3,
        validation: 'required'
    },
    'obligatedToWithholdTaxUntil1': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Obligated to withhold tax until",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Obligated to withhold tax until",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "31.12.9999",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 3
    },
    'indicatorForWithholdingTaxType2': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Indicator for withholding tax type",
            classes: ["label-title required-symbol"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Indicator for withholding tax type",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 3,
        validation: 'required'
    },
    'withholdingTaxCode2': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Withholding tax code",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Withholding tax code",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 3
    },
    'indicatorWithholdingTaxAgent2': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Indicator: Withholding tax agent?",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Indicator: Withholding tax agent?",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "X",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 3
    },
    'withholdingTaxIdentificationNumber2': {
        wrapperClasses: ["form-element input-element blue"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Withholding tax identification number",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Withholding tax identification number",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 3
    },
    'obligatedToWithholdTaxFrom2': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Obligated to withhold tax from",
            classes: ["label-title required-symbol"]
        },
        elementType: "input",
        elementConfig: {
            type: "date",
            placeholder: "Obligated to withhold tax from",
            // maxLength: INPUT_MAX_LENGTH.LENGTH_HUNDRED,
            tabIndex: 0,
            disabled: false,
            //min: new Date().toISOString().slice(0, 10),
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 3,
        validation: 'required'
    },
    'obligatedToWithholdTaxUntil2': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Obligated to withhold tax until",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Obligated to withhold tax until",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "31.12.9999",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 3
    },
    'indicatorForWithholdingTaxType3': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Indicator for withholding tax type",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Indicator for withholding tax type",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 3
    },
    'withholdingTaxCode3': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Withholding tax code",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Withholding tax code",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 3
    },
    'indicatorWithholdingTaxAgent3': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Indicator: Withholding tax agent?",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Indicator: Withholding tax agent?",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "X",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 3
    },
    'withholdingTaxIdentificationNumber3': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Withholding tax identification number",
            classes: ["label-title required-symbol"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Withholding tax identification number",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 3,
        validation: 'required'
    },
    //page 4
    'obligatedToWithholdTaxFrom3': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Obligated to withhold tax from",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: "date",
            placeholder: "Obligated to withhold tax from",
            // maxLength: INPUT_MAX_LENGTH.LENGTH_HUNDRED,
            tabIndex: 0,
            disabled: false,
            //min: new Date().toISOString().slice(0, 10),
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 4
    },
    'obligatedToWithholdTaxUntil3': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Obligated to withhold tax until",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Obligated to withhold tax until",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 4
    },
    'indicatorForWithholdingTaxType4': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Indicator for withholding tax type",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Indicator for withholding tax type",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 4
    },
    'withholdingTaxCode4': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Withholding tax code",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Withholding tax code",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 4
    },
    'indicatorWithholdingTaxAgent4': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Indicator: Withholding tax agent?",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Indicator: Withholding tax agent?",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 4
    },
    'withholdingTaxIdentificationNumber4': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Withholding tax identification number",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Withholding tax identification number",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 4
    },
    'obligatedToWithholdTaxFrom4': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Obligated to withhold tax from",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: "date",
            placeholder: "Obligated to withhold tax from",
            // maxLength: INPUT_MAX_LENGTH.LENGTH_HUNDRED,
            tabIndex: 0,
            disabled: false,
            //min: new Date().toISOString().slice(0, 10),
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 4
    },
    'obligatedToWithholdTaxUntil4': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Obligated to withhold tax until",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Obligated to withhold tax until",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-6',
        page: 4
    },
    'attribute2SalesCircle': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Attribute 2(Sales Circle)",
            classes: ["label-title required-symbol"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Attribute 2(Sales Circle)",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 4,
        validation: 'required'
    },
    'taxClass1': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "TaxClass1",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter TaxClass1",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "1",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 4
    },
    'taxClass2': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "TaxClass2",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter TaxClass2",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "1",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 4
    },
    'taxClass3': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "TaxClass3",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter TaxClass3",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "1",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 4
    },
    'taxClass4': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "TaxClass4",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter TaxClass4",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "0",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 4
    },
    'taxClass5': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "TaxClass5",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter TaxClass5",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "0",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 4
    },
    'taxClass6': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "TaxClass6",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter TaxClass6",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "0",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 4
    },
    'taxClass7': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "TaxClass7",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter TaxClass7",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "0",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 4
    },
    'taxClass8': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "TaxClass8",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter TaxClass8",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "1",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 4
    },
    'taxClass9': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "TaxClass9",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter TaxClass9",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "1",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 4
    },
    'taxClass10': {
        wrapperClasses: ["form-element input-element grey"],
        inputGroupClasses: [],
        labelConfig: {
            label: "TaxClass10",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter TaxClass10",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "1",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 4
    },
    'extraField1': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Extra Field 1",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Extra Field 1",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 4
    },
    'extraField2': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Extra Field 2",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Extra Field 2",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 4
    },
    'extraField3': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Extra Field 3",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Extra Field 3",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 4
    },
    'extraField4': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Extra Field 4",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Extra Field 4",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 4
    },
    'extraField5': {
        wrapperClasses: ["form-element input-element"],
        inputGroupClasses: [],
        labelConfig: {
            label: "Extra Field 5",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: 'text',
            placeholder: "Enter Extra Field 5",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        widthClass: 'col-sm-3',
        page: 4
    },
})