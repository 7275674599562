import {INPUT_MAX_LENGTH, ROLES_CATEGORY} from '../../constants';
import {numberFormatter} from '../../helper';

export const DefaultIntentFormState = () => ({
  firmDetails: {
    isHeader: "Firm Details",
  },
  lineOfBusiness: {
    wrapperClasses: ["form-element w-50 select-control"],
    labelConfig: {
      label: "Line of business",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      placeholder: "Line of business",
      tabIndex: 0,
      options: [{ label: "Select", value: "" }],
      disabled: false,
    },
    value: 1,
    errorMessage: "",
    valid: true,
    touched: false,
  },
  firmName: {
    wrapperClasses: ["form-element w-50"],
    labelConfig: {
      label: "Firm Name",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Firm Name",
      maxLength: INPUT_MAX_LENGTH.LENGTH_ONE_FIFTY,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
  },
  establishmentYear: {
    wrapperClasses: ["form-element w-50 select-control"],
    labelConfig: {
      label: "Year of Establishment",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      placeholder: "Year of Establishment",
      tabIndex: 0,
      options: [{ label: "Select", value: "" }, ...yearRange()],
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
  },
  firmMobileNumber: {
    wrapperClasses: ["form-element w-50"],
    labelConfig: {
      label: "Mobile Number",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Mobile Number",
      maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
      tabIndex: 0,
      disabled: true,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
  },
  firmEmailAddress: {
    wrapperClasses: ["form-element w-50"],
    labelConfig: {
      label: "Email Address",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Email Address",
      maxLength: INPUT_MAX_LENGTH.LENGTH_EIGHTY,
      tabIndex: 0,
      disabled: true,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
  },
  address: {
    wrapperClasses: ["form-element textarea-field"],
    labelConfig: {
      label: "Address",
      classes: ["label-title required-symbol"],
    },
    elementType: "textarea",
    elementConfig: {
      placeholder: "Address",
      maxLength: INPUT_MAX_LENGTH.LENGTH_ONE_FIFTY,
      tabIndex: 0,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
  },
  pinCode: {
    wrapperClasses: ["form-element w-33"],
    labelConfig: {
      label: "Pin Code",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Pin Code",
      maxLength: INPUT_MAX_LENGTH.LENGTH_SIX,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
  },
  town: {
    wrapperClasses: ["form-element w-33"],
    labelConfig: {
      label: "Town-Location",
      classes: ["label-title required-symbol"],
    },
    elementType: "customSelect",
    // elementType: "dataList",
    elementConfig: {
      placeholder: "Town-Location",
      tabIndex: 0,
      options: [{ label: "Select", value: "" }],
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
  },
  city: {
    wrapperClasses: ["form-element select-control w-33 d-none"],
    labelConfig: {
      label: "city",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      placeholder: "city",
      tabIndex: 0,
      options: [{ label: "Select", value: "" }],
      disabled: true,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
  },
  state: {
    wrapperClasses: ["form-element select-control w-33 no-select-bar"],
    labelConfig: {
      label: "State",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      type: "text",
      placeholder: "State",
      tabIndex: 0,
      options: [],
      disabled: true,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
  },
  panNumber: {
    wrapperClasses: ["form-element w-100"],
    labelConfig: {
      label: "PAN Number",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Enter Pan Number",
      maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
  },
  gst: {
    wrapperClasses: ["form-element"],
    labelConfig: {
      label: "GSTIN Number",
      classes: ["label-title"],
    },
    elementType: "text",
    elementConfig: {
      type: "text",
      placeholder: "GSTIN",
      maxLength: 15,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    radioConfig: [
      {
        labelConfig: {
          label: "Yes",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "Yes",
          name: "gst",
          tabIndex: 0,
          checked: true,
          disabled: false,
        },
      },
      {
        labelConfig: {
          label: "No",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "No",
          name: "gst",
          tabIndex: 0,
          checked: false,
          disabled: false,
        },
      },
    ],
  },
  businessDetailsHeader: {
    isHeader: "Business Details",
  },
  businessTurnover: {
    wrapperClasses: ["form-element rupee bd-50"],
    labelConfig: {
      label: "Current Turnover",
      classes: ["label-title"],
      optional: ["(Rs)"],
    },
    elementType: "input",
    elementConfig: {
      type: "tel",
      placeholder: "Current Turnover",
      maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
  },
  currentManpower: {
    wrapperClasses: ["form-element bd-50"],
    labelConfig: {
      label: "Current Manpower",
      classes: ["label-title"],
      // optional: ["(optional)"]
    },
    elementType: "input",
    elementConfig: {
      type: "tel",
      placeholder: "Current Manpower",
      maxLength: INPUT_MAX_LENGTH.LENGTH_FIVE,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
  },
  currentBusinessSubHeader: {
    isSubHeader: "Current Business Association",
    // isRequired: true
  },
  currentBusinessSelectInputConfig: [
    {
      wrapperClasses: ["form-element select-input-form"],
      inputGroupClasses: ["select-input  show-placeholder"],
      elementType: "text",
      elementConfig: {
        type: "text",
        placeholder: "Enter Brand Name",
        maxLength: INPUT_MAX_LENGTH.LENGTH_TWENTY_FOUR,
        tabIndex: 0,
        disabled: false,
      },
      value: "",
      id: "",
      errorMessage: "",
      valid: true,
      touched: false,
      selectConfig: {
        elementConfig: {
          type: "select",
          value: "",
          placeholder: "Select",
          tabIndex: 0,
          options: [{ label: "Select", value: "" }],
          disabled: false,
        },
      },
      buttonConfig: {
        type: "More",
        text: "+",
        buttonClass: "add-btn",
      },
    },
  ],
  existingRelationshipHeader: {
    isHeader: "Existing Relationship",
  },
  existingRelationship: {
    withoutInput: true,
    wrapperClasses: ["form-element radio-btn-group w-50 top-space-removed"],
    labelConfig: {
      label: "Existing Relationship with Tata Play",
      classes: ["label-title", "pos-rel"],
    },
    valid: true,
    radioConfig: [
      {
        labelConfig: {
          label: "Yes",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "Yes",
          name: "existingRelationship",
          tabIndex: 0,
          checked: true,
          disabled: false,
        },
      },
      {
        labelConfig: {
          label: "No",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "No",
          name: "existingRelationship",
          tabIndex: 0,
          checked: false,
          disabled: false,
        },
      },
    ],
  },
  selectInputConfig: [
    {
      wrapperClasses: ["form-element select-input-form"],
      inputGroupClasses: ["select-input show-placeholder"],
      elementType: "text",
      elementConfig: {
        type: "text",
        placeholder: "Enter Applicant Code",
        maxLength: INPUT_MAX_LENGTH.PARTNER_CODE_MAX_LENGTH,
        tabIndex: 0,
        disabled: false,
      },
      value: "",
      id: "",
      errorMessage: "",
      valid: true,
      touched: false,
      selectConfig: {
        elementConfig: {
          type: "select",
          value: "",
          placeholder: "Select",
          tabIndex: 0,
          options: [{ label: "Select", value: "" }],
          disabled: false,
        },
      },
      // dataListConfig: {
      //     elementConfig: {
      //         type: "datalist",
      //         value: "",
      //         placeholder: "Select",
      //         tabIndex: 0,
      //         options: [{label: "Select", value: ""}],
      //         disabled: false
      //     }
      // },
      buttonConfig: {
        type: "More",
        text: "+",
        buttonClass: "add-btn",
      },
    },
  ],
  termsAndCondition: {
    isTermsAndCondition: true,
    value: false,
    valid: true,
    errorMessage: "",
  },
});

export const yearRange = () => {
    let dt = new Date();
    let currentYear = dt.getFullYear();
    let yearRange = [];
    for (let i = currentYear; i >= 1901; i--) {
        yearRange.push({label: i, value: i});
    }
    return yearRange;
};

export const setIntentFormForSecondApplication = (updatedIntentForm) => {
    updatedIntentForm.firmName.elementConfig.disabled = true;
    updatedIntentForm.establishmentYear.elementConfig.disabled = true;
    updatedIntentForm.panNumber.elementConfig.disabled = true;
    updatedIntentForm.gst.elementConfig.disabled = true;
    // updatedIntentForm.gst.radioConfig[0].elementConfig.disabled = true;
    // updatedIntentForm.gst.radioConfig[1].elementConfig.disabled = true;
    updatedIntentForm.address.elementConfig.disabled = true;
    updatedIntentForm.pinCode.elementConfig.disabled = true;
    updatedIntentForm.town.elementConfig.disabled = true;
    return updatedIntentForm;
}

export const setEvaluationFormForSecondApplication = (updatedEvaluationForm) => {
    updatedEvaluationForm.shopArea.elementConfig.disabled = true;
    updatedEvaluationForm.bankDetails[0].bank.elementConfig.disabled = true;
    updatedEvaluationForm.bankDetails[0].otherBank.elementConfig.disabled = true;
    return updatedEvaluationForm;
}

export const setSoftCopyFormForSecondApplication = (updatedDocumentsSoftCopyForm, bankDetails) => {
    if(bankDetails.accountNo && bankDetails.ccLimit) {
        updatedDocumentsSoftCopyForm.totalArea.elementConfig.disabled = true;
        updatedDocumentsSoftCopyForm.managerName.elementConfig.disabled = true;
        updatedDocumentsSoftCopyForm.managerMobileNo.elementConfig.disabled = true;
        updatedDocumentsSoftCopyForm.bankDetails[0].bankName.elementConfig.disabled = true;
        updatedDocumentsSoftCopyForm.bankDetails[0].otherBank.elementConfig.disabled = true;
        updatedDocumentsSoftCopyForm.bankDetails[0].bankAccountNumber.elementConfig.disabled = true;
        updatedDocumentsSoftCopyForm.bankDetails[0].ccLimit.elementConfig.disabled = true;
        updatedDocumentsSoftCopyForm.address.elementConfig.disabled = true;
        updatedDocumentsSoftCopyForm.pinCode.elementConfig.disabled = true;
        updatedDocumentsSoftCopyForm.town.elementConfig.disabled = true;
    }
    updatedDocumentsSoftCopyForm.evdMobileNo.elementConfig.disabled = true;
    updatedDocumentsSoftCopyForm.evdEmailAddress.elementConfig.disabled = true;

    return updatedDocumentsSoftCopyForm;
}

export const responseMapperForIntent = (data, currentForm) => {
    const singleKeyMapper = {
        firmName: "name",
        establishmentYear: "yearOfEstablishment",
        firmMobileNumber: "mobileNumber",
        firmEmailAddress: "email",
        panNumber: "panNo",
        businessTurnover: "turnover",
        currentManpower: "manPower",
    };

    for (let key in singleKeyMapper) {
        currentForm[key] = {
            ...currentForm[key],
            value: key==='businessTurnover' ? numberFormatter(data[singleKeyMapper[key]]) : data[singleKeyMapper[key]]  || ""
        };
    }
    // for line of business
    currentForm.lineOfBusiness = {
        ...currentForm.lineOfBusiness,
        value: data.lineOfBusiness.id
    }
    // for address line
    currentForm.address = {
        ...currentForm.address,
        value: (data.address && data.address.line) || ""
    };

    // for pinCode line
    currentForm.pinCode = {
        ...currentForm.pinCode,
        value:
            (data.address && data.address.pinCode && data.address.pinCode.code) || ""
    };

    const stateCityMapKey = {state: "id", city: "id", town: "id"};

    for (let key in stateCityMapKey) {
        currentForm[key] = {
            ...currentForm[key],
            value:
                (data.address &&
                    data.address[key] &&
                    data.address[key][stateCityMapKey[key]]) ||
                ""
        };
    }

    //for gst and existing rel-n radio
    const gstExistRelMapKey = [
        {
            localKey: "gst",
            responseKey: "gstinExist",
            inputValueKey: "gstin"
        },
        {
            localKey: "existingRelationship",
            responseKey: "existingPartnerShip",
            inputValueKey: ""
        }
    ];

    gstExistRelMapKey.forEach(element => {
        const {
            [element.localKey]: {radioConfig}
        } = currentForm;
        radioConfig.forEach(value => {
            value.elementConfig.checked = false;
        });

        let index = data[element.responseKey] ? 0 : 1;
        radioConfig[index].elementConfig.checked = true;
        currentForm[[element.localKey]] = {
            ...currentForm[[element.localKey]],
            value: data[element.inputValueKey] || "",
            radioConfig: [...radioConfig]
        };
    });
    return currentForm;
};

export const requestMapperForIntent = (currentForm, intentFormIds, buttonType) => {
    let businessAssociationsList = [];
    currentForm.currentBusinessSelectInputConfig.map(item => {
        if (item.value && item.selectConfig.elementConfig.value) {
            businessAssociationsList.push({
                id: item.id,
                digitalService: {
                    id: item.selectConfig.elementConfig.value
                },
                company: item.value
            });
        }
    });

    let existingPartnerShipsList = [];
    currentForm.selectInputConfig.map(item => {
        if (item.value && item.selectConfig.elementConfig.value) {
            existingPartnerShipsList.push({
                id: item.id,
                partnerCode: {
                    code: item.value
                },
                channel: {
                    id: item.selectConfig.elementConfig.value
                }
            });
        }
    });

    let responseForm = {
        lineOfBusiness:{
            id: currentForm.lineOfBusiness.value,
            name: currentForm.lineOfBusiness.elementConfig.options.filter(lob=> lob.value == currentForm.lineOfBusiness.value)[0].label
        },
        firm: {
            id: intentFormIds.firmId,
            name: currentForm.firmName.value || "",
            yearOfEstablishment: currentForm.establishmentYear.value || "",
            address: {
                id: intentFormIds.addressId || "",
                line: currentForm.address.value || "",
                pinCode: {
                    code: currentForm.pinCode.value || ""
                },
                state: {
                    id: currentForm.state.value || ""
                },
                city: {
                    id: currentForm.city.value || "",
                    state: {
                        id: currentForm.state.value || ""
                    }
                },
                town: {
                    id: currentForm.town.value || ""
                }
            },
            panExist: true,
            panNo: currentForm.panNumber.value || "",
            gstinExist: currentForm.gst.radioConfig[0].elementConfig.checked || false,
            gstin: currentForm.gst.radioConfig[0].elementConfig.checked
                ? currentForm.gst.value || ""
                : ""
        },
        existingPartnerShip:
            currentForm.existingRelationship.radioConfig[0].elementConfig.checked ||
            false,
        contactPerson: {
            id: intentFormIds.personId || "",
            name: currentForm.firmName.value || "",
            mobileNumber: currentForm.firmMobileNumber.value || "",
            email: currentForm.firmEmailAddress.value || ""
        },
        businessDetail: {
            id: intentFormIds.businessDetailsId || "",
            turnover: (currentForm.businessTurnover.value && currentForm.businessTurnover.value.replace(/,/g,'')) || "",
            manPower: currentForm.currentManpower.value || "",
            businessAssociations:
                businessAssociationsList && businessAssociationsList.length
                    ? businessAssociationsList
                    : []
        },
        existingPartnerShips: currentForm.existingRelationship.radioConfig[0]
            .elementConfig.checked
            ? existingPartnerShipsList && existingPartnerShipsList.length
                ? existingPartnerShipsList
                : []
            : [],
        actionId: (buttonType && buttonType.id) || '',
            intentFormPrivacy: currentForm.termsAndCondition.value || false,
    };
    return responseForm;
};

export const currentBusinessAssociationEvalForm = () => ({
    id: {
        value: ''
    },
    curAssoc: {
        containerClass: "form-control-group",
        wrapperClasses: ["form-element select-input-form without-btn-control"],
        inputGroupClasses: ["select-input"],
        elementType: "text",
        elementConfig: {
            type: "text",
            placeholder: "Enter Applicant Code",
            maxLength: INPUT_MAX_LENGTH.LENGTH_TWENTY_FOUR,
            tabIndex: 0
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        selectConfig: {
            elementConfig: {
                type: "select",
                value: "",
                placeholder: "Select",
                tabIndex: 0,
                options: [{label: "Select", value: ""}]
            }
        }
    },
    marketArea: {
        wrapperClasses: ["form-element w-33 market-m"],
        labelConfig: {
            label: "Market Area Covered",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: "text",
            placeholder: "",
            maxLength: INPUT_MAX_LENGTH.LENGTH_TWELVE,
            tabIndex: 0,
            disabled: false
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false
    },
    since: {
        wrapperClasses: ["form-element w-33"],
        labelConfig: {
            label: "Since",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: "text",
            placeholder: "",
            maxLength: 4,
            tabIndex: 0,
            disabled: false
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false
    },
    sisterConcern: {
        wrapperClasses: ["form-element w-33 mr0"],
        labelConfig: {
            label: "Sister Concern",
            classes: ["label-title"]
        },
        elementType: "input",
        elementConfig: {
            type: "text",
            placeholder: "",
            maxLength: INPUT_MAX_LENGTH.LENGTH_TWENTY_FOUR,
            tabIndex: 0,
            disabled: false
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false
    },
    remark: {
        wrapperClasses: ["form-element textarea-field"],
        labelConfig: {
            label: "Remark",
            classes: ["label-title"]
        },
        elementType: "textarea",
        elementConfig: {
            placeholder: "Remark",
            tabIndex: 0,
            maxLength: INPUT_MAX_LENGTH.LENGTH_EIGHTY,
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false
    }
});

export const numOfVehicleList = () => {
    let numOfVehicleList = [];
    for (let i = 1; i <= 6; i++) {
      if(i>5) {
        numOfVehicleList.push({label: "5+", value: "5+"});
      } else numOfVehicleList.push({label: i, value: i});
    }
    return numOfVehicleList;
};

export const storeDescriptionList = () => {
    let descriptionOptions = [];
    const descriptionList = ['Store on Cross Road', 'Junction', 'High Foot Fall Area', 'Popular Land Mark', 'Others'];
    for(let i = 0; i< descriptionList.length; i++) {
        descriptionOptions.push({label: descriptionList[i], value : i});
    }
    return descriptionOptions;
}

export const dropdownYesNo = () => {
    return [
        {label: "Yes", value: "Yes"},
        {label: "No", value: "No"}
    ];
};

export const DefaultBankEvalFormObj = () => ({
    id: "",
    bank: {
        wrapperClasses: ["form-element select-control w-33"],
        labelConfig: {
            label: "Bank Name",
            classes: ["label-title required-symbol"]
        },
        elementType: "select",
        placeholder: "Bank Name",
        elementConfig: {
            type: "select",
            placeholder: "Bank Name",
            tabIndex: 0,
            options: [{ label: "Select", value: "" }],
            disabled: false,
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
        isOther: false
    },
    otherBank: {
        wrapperClasses: ["form-element w-25"],
        labelConfig: {
            label: "Name",
            classes: ["label-title required-symbol"]
        },
        elementType: "input",
        elementConfig: {
            type: "text",
            placeholder: "Name",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTY,
            tabIndex: 0,
            disabled: false
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false
    },
});

export const DefaultPincodesFormObj = () => ({
    id:"",
    pinCodes:{
    wrapperClasses: ["form-element w-33 m-top-0"],
    labelConfig: {
        label: "Pincode",
        classes: ["label-title required-symbol"]
    },
    elementType: "input",
    placeholder: "Pincode",
    elementConfig: {
        type: "number",
        placeholder: "Pincode",
        maxLength: INPUT_MAX_LENGTH.LENGTH_SIX,
        tabIndex: 0,
        disabled: false
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true
}
});

export const DefaultEvaluationFormState = () => ({
  firmType: {
    wrapperClasses: ["form-control-group form-element border-white w-50"],
    labelConfig: {
      label: "Entity Type",
      classes: ["label-title"],
    },
    radioConfig: [
      {
        labelConfig: {
          label: "Proprietorship",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "PROPRIETOR",
          name: "firmType",
          tabIndex: 0,
          checked: true,
          disabled: false,
        },
      },
      {
        labelConfig: {
          label: "Partnership",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "PARTNER",
          name: "firmType",
          tabIndex: 0,
          checked: false,
          disabled: false,
        },
      },
      {
        labelConfig: {
          label: "Company",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "COMPANY",
          name: "firmType",
          tabIndex: 0,
          checked: false,
          disabled: false,
        },
      },
    ],
  },
  shopOwnerShip: {
    wrapperClasses: ["form-control-group form-element border-white w-50"],
    labelConfig: {
      label: "Shop Ownership",
      classes: ["label-title"],
    },
    radioConfig: [
      {
        labelConfig: {
          label: "Rented",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "RENTED",
          name: "shopOwnerShip",
          tabIndex: 0,
          checked: true,
          disabled: false,
        },
      },
      {
        labelConfig: {
          label: "Owned",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "OWNED",
          name: "shopOwnerShip",
          tabIndex: 0,
          checked: false,
          disabled: false,
        },
      },
    ],
  },
  shopArea: {
    wrapperClasses: ["form-element w-50"],
    labelConfig: {
      label: "Shop Area (sq ft)",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "tel",
      placeholder: "",
      maxLength: INPUT_MAX_LENGTH.LENGTH_FIVE,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
  },
  wareHouseAvailable: {
    withoutInput: true,
    valid: true,
    wrapperClasses: ["form-element radio-btn-group w-50"],
    labelConfig: {
      label: "Warehouse Available",
      classes: ["label-title required-symbol"],
    },
    radioConfig: [
      {
        labelConfig: {
          label: "Yes",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "Yes",
          name: "wareHouseAvailable",
          tabIndex: 0,
          checked: true,
          disabled: false,
        },
      },
      {
        labelConfig: {
          label: "No",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "No",
          name: "wareHouseAvailable",
          tabIndex: 0,
          checked: false,
          disabled: false,
        },
      },
    ],
  },
  bankDetailsHeader: {
    isHeader: "Bank Details",
  },
  bankDetails: [DefaultBankEvalFormObj()],
  businessDetailsHeader: {
    isHeader: "Business Details",
  },
  deliveryVehicle: {
    wrapperClasses: ["form-element w-50 select-control delivery"],
    labelConfig: {
      label: "Existing Delivery Vehicle",
      classes: ["label-title"],
    },
    elementType: "select",
    elementConfig: {
      type: "select",
      value: "",
      placeholder: "Existing Delivery Vehicle",
      tabIndex: 0,
      options: [{ label: "Select", value: "" }, ...numOfVehicleList()],
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
  },
  manpowerCapacity: {
    wrapperClasses: ["form-element w-50 manpower"],
    labelConfig: {
      label: "Existing Manpower Capacity",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Existing Manpower Capacity",
      maxLength: 50,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
  },
  currentBuAssocSubHeader: {
    isSubHeader: "Current Business Association",
    // isRequired: true
  },
  currentBusinessAssociation: [currentBusinessAssociationEvalForm()],
  partnerHeader: {
    isHeader: "Tata Play Association",
    classes: " clearfix",
  },
  partnerType: {
    wrapperClasses: ["form-element w-50 select-control partner"],
    labelConfig: {
      label: "Applicant Type",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      type: "select",
      value: "",
      placeholder: "Applicant Type",
      tabIndex: 0,
      options: [{ label: "Select", value: "" }],
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
  },
  territoryCovered: {
    wrapperClasses: ["form-element w-50 territory"],
    labelConfig: {
      label: "Territory Covered (sq km)",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Territory Covered",
      maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
  },
  expectedTurnover: {
    wrapperClasses: ["form-element w-50 m-margin-0"],
    labelConfig: {
      label: "Expected Turnover",
      classes: ["label-title required-symbol"],
      optional: ["(Rs)"],
    },
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Expected Turnover",
      maxLength: INPUT_MAX_LENGTH.LENGTH_NINE,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
  },
  // storeForm: {
  basicDetailsHeader: {
    isHeader: "Basis Details Of Partner",
    formTypeStore: true,
  },
  partnerName: {
    wrapperClasses: ["form-element w-50 partner"],
    labelConfig: {
      label: "Name of Partner",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Name of Partner",
      maxLength: INPUT_MAX_LENGTH.LENGTH_HUNDRED,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  firmName: {
    wrapperClasses: ["form-element w-50 m-right-0"],
    labelConfig: {
      label: "Firm Name",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Firm Name",
      maxLength: INPUT_MAX_LENGTH.LENGTH_HUNDRED,
      tabIndex: 0,
      disabled: true,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  firmAddress: {
    wrapperClasses: ["form-element textarea-field"],
    labelConfig: {
      label: "Firm Address",
      classes: ["label-title required-symbol"],
    },
    elementType: "textarea",
    elementConfig: {
      placeholder: "Firm Address",
      maxLength: INPUT_MAX_LENGTH.LENGTH_ONE_FIFTY,
      tabIndex: 0,
      disabled: true,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  pinCode: {
    wrapperClasses: ["form-element w-33"],
    labelConfig: {
      label: "Pin Code",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Pin Code",
      maxLength: INPUT_MAX_LENGTH.LENGTH_SIX,
      tabIndex: 0,
      disabled: true,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  town: {
    wrapperClasses: ["form-element w-33"],
    labelConfig: {
      label: "Town-Location",
      classes: ["label-title required-symbol"],
    },
    elementType: "customSelect",
    // elementType: "dataList",
    elementConfig: {
      placeholder: "Town-Location",
      tabIndex: 0,
      options: [{ label: "Select", value: "" }],
      disabled: true,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  city: {
    wrapperClasses: ["form-element select-control w-33 d-none"],
    labelConfig: {
      label: "city",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      placeholder: "city",
      tabIndex: 0,
      options: [{ label: "Select", value: "" }],
      disabled: true,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  state: {
    wrapperClasses: ["form-element select-control w-33 no-select-bar"],
    labelConfig: {
      label: "State",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      type: "text",
      placeholder: "State",
      tabIndex: 0,
      options: [],
      disabled: true,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  familyDetails: {
    wrapperClasses: ["form-element w-50 partner"],
    labelConfig: {
      label: "Family Details",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Family Details",
      maxLength: INPUT_MAX_LENGTH.LENGTH_HUNDRED,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  prevExperience: {
    wrapperClasses: ["form-element w-50 m-right-0 m-top-20"],
    labelConfig: {
      label: "Previous business & experience in retail",
      classes: ["label-title required-symbol retail"],
    },
    elementType: "select",
    elementConfig: {
      placeholder: "select",
      tabIndex: 0,
      options: [{ label: "Select", value: "", points: "", disabledFields: "" }],
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
    isGradeAvailable: true,
    selectConfig: {
      elementConfig: {
        type: "select",
        value: "",
        placeholder: "Select",
        tabIndex: 0,
        options: [{ label: "Select", value: "" }],
      },
    },
  },
  isFamilyInDTH: {
    wrapperClasses: ["form-element m-top-0"],
    labelConfig: {
      label:
        "Is any familiy member into runing DTH business. if yes, enter family details",
      classes: ["label-title family-label required-symbol"],
    },
    elementType: "text",
    elementConfig: {
      type: "text",
      placeholder: "Is any familiy member into runing DTH business",
      maxLength: 15,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
    radioConfig: [
      {
        labelConfig: {
          label: "Yes",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "Yes",
          name: "isFamilyInDTH",
          tabIndex: 0,
          checked: true,
          disabled: false,
        },
      },
      {
        labelConfig: {
          label: "No",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "No",
          name: "isFamilyInDTH",
          tabIndex: 0,
          checked: false,
          disabled: false,
        },
      },
    ],
  },
  proposedStoreHeader: {
    isHeader: "Basic details on Proposed Stores",
    formTypeStore: true,
  },
  townName: {
    wrapperClasses: ["form-element w-50 partner m-top-0"],
    labelConfig: {
      label: "Town Name/Town Code",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Town Name/Town Code",
      maxLength: INPUT_MAX_LENGTH.LENGTH_HUNDRED,
      tabIndex: 0,
      disabled: true,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  isCertifiedToOpen: {
    withoutInput: true,
    valid: true,
    wrapperClasses: [
      "form-element radio-btn-group w-50 double-line m-right-0 m-top-0",
    ],
    labelConfig: {
      label: "Whether town name is certified for store opening",
      classes: ["label-title required-symbol"],
    },
    radioConfig: [
      {
        labelConfig: {
          label: "Yes",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "Yes",
          name: "isCertifiedToOpen",
          tabIndex: 0,
          checked: true,
        },
      },
      {
        labelConfig: {
          label: "No",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "No",
          name: "isCertifiedToOpen",
          tabIndex: 0,
          checked: false,
        },
      },
    ],
    formTypeStore: true,
  },
  storeLocation: {
    wrapperClasses: ["form-element textarea-field m-top-0"],
    labelConfig: {
      label: "Store Location",
      classes: ["label-title required-symbol"],
    },
    elementType: "textarea",
    elementConfig: {
      placeholder: "Store Location",
      maxLength: INPUT_MAX_LENGTH.LENGTH_ONE_FIFTY,
      tabIndex: 0,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  areaOperation: {
    wrapperClasses: ["form-element textarea-field m-top-0"],
    labelConfig: {
      label: "Area of operation of store",
      classes: ["label-title required-symbol"],
    },
    elementType: "textarea",
    elementConfig: {
      placeholder: "Area of operation of store",
      maxLength: INPUT_MAX_LENGTH.LENGTH_ONE_FIFTY,
      tabIndex: 0,
      disabled: true,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  projectedAcquisition: {
    wrapperClasses: ["form-element double-line w-50 partner m-top-0"],
    labelConfig: {
      label: "Projected acquisition from proposed store - Yearly",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "number",
      placeholder: "Projected acquisition from proposed store",
      maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  projectedRecharge: {
    wrapperClasses: ["form-element double-line w-50 m-right-0 m-top-0"],
    labelConfig: {
      label: "Projected recharge base of proposed store - Yearly",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "number",
      placeholder: "Projected recharge base of proposed store",
      maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  numberOfMonths: {
    wrapperClasses: ["form-element w-50 double-line partner m-top-0"],
    labelConfig: {
      label: "Number of months for self-sustaining as per ROI",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "number",
      placeholder: "Number of months for self-sustaining as per ROI",
      maxLength: INPUT_MAX_LENGTH.LENGTH_THREE,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  agreementWithStoreOwner: {
    withoutInput: true,
    valid: true,
    wrapperClasses: [
      "form-element radio-btn-group double-line w-50 m-right-0 m-top-0",
    ],
    labelConfig: {
      label: "Partner has to enter minimum 3 year agreement with store owner",
      classes: ["label-title required-symbol"],
    },
    radioConfig: [
      {
        labelConfig: {
          label: "Yes",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "Yes",
          name: "agreementWithStoreOwner",
          tabIndex: 0,
          checked: true,
        },
      },
      {
        labelConfig: {
          label: "No",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "No",
          name: "agreementWithStoreOwner",
          tabIndex: 0,
          checked: false,
        },
      },
    ],
    formTypeStore: true,
  },
  checkingBasicInfoHeader: {
    isHeader: "A) Checking on basic information of location",
    formTypeStore: true,
  },
  populationOfLocality: {
    wrapperClasses: ["form-element w-50 m-margin-15 m-top-0"],
    labelConfig: {
      label: "Population of the Locality",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "number",
      placeholder: "Population of the Locality",
      maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  numberOfHouseholds: {
    wrapperClasses: ["form-element w-50 m-margin-0 m-top-0"],
    labelConfig: {
      label: "No of households in the Locality",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "number",
      placeholder: "No of households in the Locality",
      maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  pinCodes: [DefaultPincodesFormObj()],
  activeSubscriber: {
    wrapperClasses: ["form-element w-50 m-top-0"],
    labelConfig: {
      label: "Active subscriber base - TS",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "number",
      placeholder: "Active subscriber base - TS",
      maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
      tabIndex: 0,
      disabled: true,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  percentageShare: {
    wrapperClasses: ["form-element w-50 m-right-15 m-top-0"],
    labelConfig: {
      label: "Percentage share of TS",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      placeholder: "select",
      tabIndex: 0,
      options: [{ label: "Select", value: "", points: "", disabledFields: "" }],
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
    isGradeAvailable: true,
    selectConfig: {
      elementConfig: {
        type: "select",
        value: "",
        placeholder: "Select",
        tabIndex: 0,
        options: [{ label: "Select", value: "" }],
      },
    },
  },
  activeOtherSubscriber: {
    wrapperClasses: ["form-element w-50 m-right-0 m-top-0"],
    labelConfig: {
      label: "Active subscriber base - Other DTH",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "number",
      placeholder: "Active subscriber base - Other DTH",
      maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  activeCableSubscriber: {
    wrapperClasses: ["form-element w-50 m-right-15 m-top-0"],
    labelConfig: {
      label: "Active subscriber base - Cable",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      placeholder: "select",
      tabIndex: 0,
      options: [{ label: "Select", value: "", points: "", disabledFields: "" }],
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
    isGradeAvailable: true,
    selectConfig: {
      elementConfig: {
        type: "select",
        value: "",
        placeholder: "Select",
        tabIndex: 0,
        options: [{ label: "Select", value: "" }],
      },
    },
  },
  monthlyCableSubscription: {
    wrapperClasses: ["form-element w-50 m-right-0 m-top-0"],
    labelConfig: {
      label: "Monthly cable subscription amount",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "number",
      placeholder: "Monthly cable subscription amount",
      maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  rechargeBase: {
    wrapperClasses: ["form-element w-50 m-top-0"],
    labelConfig: {
      label: "Recharge base @ average ARPU - TS",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "number",
      placeholder: "Recharge base @ average ARPU - TS",
      maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  checkingAdditionalInfoHeader: {
    isHeader: "B)  Checking on Additional information of Location",
    formTypeStore: true,
  },
  isTSBBPresence: {
    wrapperClasses: ["form-element radio-btn-group w-50 m-right-15 m-top-0"],
    labelConfig: {
      label: "Whether TSBB is having presence",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      placeholder: "select",
      tabIndex: 0,
      options: [{ label: "Select", value: "", points: "", disabledFields: "" }],
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
    isGradeAvailable: true,
    selectConfig: {
      elementConfig: {
        type: "select",
        value: "",
        placeholder: "Select",
        tabIndex: 0,
        options: [{ label: "Select", value: "" }],
      },
    },
  },
  allClusterName: {
    wrapperClasses: ["form-element w-50 double-line m-top-0 m-right-0"],
    labelConfig: {
      label: "All Cluster Name which can be serviced by Store",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "All Cluster Name which can be serviced by Store",
      maxLength: INPUT_MAX_LENGTH.LENGTH_HUNDRED,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  numberOfHomepass: {
    wrapperClasses: ["form-element w-50 m-top-0"],
    labelConfig: {
      label: "No of homepass",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "number",
      placeholder: "No of homepass",
      maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  checkingDimensionHeader: {
    isHeader: "C)  Checking on Store dimension and Layout",
    formTypeStore: true,
  },
  isStoreOnRoad: {
    wrapperClasses: ["form-element radio-btn-group w-50 m-right-15 m-top-0"],
    labelConfig: {
      label: "Is the Store on main road",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      placeholder: "select",
      tabIndex: 0,
      options: [{ label: "Select", value: "", points: "", disabledFields: "" }],
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
    isGradeAvailable: true,
    selectConfig: {
      elementConfig: {
        type: "select",
        value: "",
        placeholder: "Select",
        tabIndex: 0,
        options: [{ label: "Select", value: "" }],
      },
    },
  },
  sizeOfStore: {
    wrapperClasses: ["form-element w-50 m-right-0 m-top-0"],
    labelConfig: {
      label: "Size of store ( LxB - In Sq Ft )",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "number",
      placeholder: "",
      maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  briefDescription: {
    wrapperClasses: ["form-element w-50 m-right-15 select-control m-top-0"],
    labelConfig: {
      label: "Brief Description of Location",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      type: "select",
      value: "",
      placeholder: "Brief Description of Location",
      tabIndex: 0,
      options: [{ label: "Select", value: "" }, ...storeDescriptionList()],
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  otherDescription: {
    wrapperClasses: ["form-element w-50 m-right-0 m-top-0"],
    labelConfig: {
      label: "Other Description",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "input",
      placeholder: "Other Description",
      maxLength: INPUT_MAX_LENGTH.LENGTH_HUNDRED,
      tabIndex: 0,
      disabled: true,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  minimumFrontage: {
    wrapperClasses: ["form-element w-50 double-line m-right-15 m-top-0"],
    labelConfig: {
      label: "Minimum frontage (Shutter Size In Ft)",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "number",
      placeholder: "Minimum frontage",
      maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  isInsideStore: {
    withoutInput: true,
    valid: true,
    wrapperClasses: [
      "form-element radio-btn-group double-line w-50 m-right-0 m-top-0",
    ],
    labelConfig: {
      label:
        "Whether the inside of Store is having hindrance of pillar or beam",
      classes: ["label-title required-symbol"],
    },
    radioConfig: [
      {
        labelConfig: {
          label: "Yes",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "Yes",
          name: "isInsideStore",
          tabIndex: 0,
          checked: true,
        },
      },
      {
        labelConfig: {
          label: "No",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "No",
          name: "isInsideStore",
          tabIndex: 0,
          checked: false,
        },
      },
    ],
    formTypeStore: true,
  },
  isStoreSurface: {
    withoutInput: true,
    valid: true,
    wrapperClasses: [
      "form-element radio-btn-group double-line w-50 m-right-15 m-top-0",
    ],
    labelConfig: {
      label:
        "Whether the store surface is plain for carrying out branding work",
      classes: ["label-title required-symbol"],
    },
    radioConfig: [
      {
        labelConfig: {
          label: "Yes",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "Yes",
          name: "isStoreSurface",
          tabIndex: 0,
          checked: true,
        },
      },
      {
        labelConfig: {
          label: "No",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "No",
          name: "isStoreSurface",
          tabIndex: 0,
          checked: false,
        },
      },
    ],
    formTypeStore: true,
  },
  nearbyNumberOfStore: {
    wrapperClasses: ["form-element w-50 double-line m-right-0 m-top-0"],
    labelConfig: {
      label: "Specify number of Airtel/Vodafone store in nearby vicinity",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Specify number of Airtel/Vodafone store in nearby vicinity",
      maxLength: INPUT_MAX_LENGTH.LENGTH_HUNDRED,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  nearbyImportantLandmark: {
    wrapperClasses: ["form-element w-50 double-line m-right-15 m-top-0"],
    labelConfig: {
      label: "Specify Important landmark in nearby vicinity",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Specify Important landmark in nearby vicinity",
      maxLength: INPUT_MAX_LENGTH.LENGTH_HUNDRED,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  nearbyPreferredStore: {
    wrapperClasses: ["form-element w-50 double-line m-right-0 m-top-0"],
    labelConfig: {
      label: "Specify number of Tata Play Preferred shore in nearby vicinity",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder:
        "Specify number of Tata Play Preferred shore in nearby vicinity",
      maxLength: INPUT_MAX_LENGTH.LENGTH_HUNDRED,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  distanceFromCurrentLocation: {
    wrapperClasses: ["form-element w-50 double-line m-right-15 m-top-0"],
    labelConfig: {
      label: "Distance from current location of Tata Play Store - In KM",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "number",
      placeholder: "Minimum frontage",
      maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  isRequiredLocalLanguage: {
    withoutInput: true,
    valid: true,
    wrapperClasses: [
      "form-element radio-btn-group double-line w-50 m-right-0 m-top-0",
    ],
    labelConfig: {
      label: "Require Local Language for Branding & POSM",
      classes: ["label-title required-symbol"],
    },
    radioConfig: [
      {
        labelConfig: {
          label: "Yes",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "Yes",
          name: "isRequiredLocalLanguage",
          tabIndex: 0,
          checked: true,
        },
      },
      {
        labelConfig: {
          label: "No",
          classes: ["radio-text"],
        },
        elementConfig: {
          type: "radio",
          value: "No",
          name: "isRequiredLocalLanguage",
          tabIndex: 0,
          checked: false,
        },
      },
    ],
    formTypeStore: true,
  },
  proposedDateOfHandover: {
    wrapperClasses: ["form-element double-line w-50 m-right-15 m-top-0"],
    labelConfig: {
      label: "Proposed date of store handing over for branding",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "date",
      placeholder: "Proposed date of store handing over for branding",
      maxLength: INPUT_MAX_LENGTH.LENGTH_HUNDRED,
      tabIndex: 0,
      disabled: false,
      min: new Date().toISOString().slice(0, 10),
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  proposedDateOfLaunch: {
    wrapperClasses: ["form-element w-50 m-right-0 m-top-0"],
    labelConfig: {
      label: "Proposed date of Launch of Store",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "date",
      placeholder: "Proposed date of Launch of Store",
      maxLength: INPUT_MAX_LENGTH.LENGTH_HUNDRED,
      tabIndex: 0,
      disabled: false,
      min: new Date().toISOString().slice(0, 10),
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  propertyDetailsHeader: {
    isHeader: "D)  Checking on Property Details",
    formTypeStore: true,
  },
  propertyBackground: {
    wrapperClasses: ["form-element w-50 m-right-15 m-top-0"],
    labelConfig: {
      label: "Property background",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      placeholder: "select",
      tabIndex: 0,
      options: [{ label: "Select", value: "", points: "", disabledFields: "" }],
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
    isGradeAvailable: true,
    selectConfig: {
      elementConfig: {
        type: "select",
        value: "",
        placeholder: "Select",
        tabIndex: 0,
        options: [{ label: "Select", value: "" }],
      },
    },
  },
  monthlyRented: {
    wrapperClasses: ["form-element w-50 m-right-0 m-top-0"],
    labelConfig: {
      label: "Monthly Rent (Rs)",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "number",
      placeholder: "Monthly Rent (Rs)",
      maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  securityDeposit: {
    wrapperClasses: ["form-element w-50 m-right-15 m-top-0"],
    labelConfig: {
      label: "Security Deposit Amount (Rs)",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "number",
      placeholder: "Security Deposit Amount (Rs)",
      maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  yearlyRental: {
    wrapperClasses: ["form-element w-50 m-right-0 m-top-0"],
    labelConfig: {
      label: "Yearly rental increase % in market",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "number",
      placeholder: "Yearly rental increase % in market",
      maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  prevThreeYearsDetail: {
    wrapperClasses: ["form-element w-50 double-line m-right-15 m-top-0"],
    labelConfig: {
      label: "Businesses Done in Last 3 Yrs In this Store",
      classes: ["label-title required-symbol"],
    },
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Businesses Done in Last 3 Yrs In this Store",
      maxLength: INPUT_MAX_LENGTH.LENGTH_HUNDRED,
      tabIndex: 0,
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  noticeFromLocalAuthority: {
    wrapperClasses: ["form-element w-50 double-line m-right-0 m-top-10"],
    labelConfig: {
      label:
        "Has the store received any notice from Local authority for road widening",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      type: "select",
      value: "",
      placeholder:
        "Has the store received any notice from Local authority for road widening",
      tabIndex: 0,
      options: [{ label: "Select", value: "" }, ...dropdownYesNo()],
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  legalDisputeNOC: {
    wrapperClasses: ["form-element w-50 double-line m-right-15 m-top-0"],
    labelConfig: {
      label: "To obtain NOC that store is not under any Legal dispute",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      type: "select",
      value: "",
      placeholder: "To obtain NOC that store is not under any Legal dispute",
      tabIndex: 0,
      options: [{ label: "Select", value: "" }, ...dropdownYesNo()],
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  electricityConnectionCapacity: {
    wrapperClasses: ["form-element w-50 double-line m-right-0 m-top-20"],
    labelConfig: {
      label:
        "Whether electricity connection capacity is sufficient for Brand Store - 5KVA load",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      type: "select",
      value: "",
      placeholder:
        "Whether electricity connection capacity is sufficient for Brand Store - 5KVA load",
      tabIndex: 0,
      options: [{ label: "Select", value: "" }, ...dropdownYesNo()],
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  waterAccumulationIssue: {
    wrapperClasses: ["form-element w-50 double-line m-right-15 m-top-0"],
    labelConfig: {
      label: "Whether water accumulation issue in rainy season",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      type: "select",
      value: "",
      placeholder: "Whether water accumulation issue in rainy season",
      tabIndex: 0,
      options: [{ label: "Select", value: "" }, ...dropdownYesNo()],
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  leakageIssue: {
    wrapperClasses: ["form-element w-50 double-line m-right-0 m-top-0"],
    labelConfig: {
      label: "Whether any leakage issue inside the store area",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      type: "select",
      value: "",
      placeholder: "Whether any leakage issue inside the store area",
      tabIndex: 0,
      options: [{ label: "Select", value: "" }, ...dropdownYesNo()],
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  storeLocationInLCO: {
    wrapperClasses: ["form-element w-50 double-line m-right-15 m-top-0"],
    labelConfig: {
      label: "Whether the store location is in LCO area",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      type: "select",
      value: "",
      placeholder: "Whether the store location is in LCO area",
      tabIndex: 0,
      options: [{ label: "Select", value: "" }, ...dropdownYesNo()],
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  ageOfBuilding: {
    wrapperClasses: ["form-element w-50 double-line m-right-0 m-top-0"],
    labelConfig: {
      label: "What is the age of the building or Store",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      placeholder: "select",
      tabIndex: 0,
      options: [{ label: "Select", value: "", points: "", disabledFields: "" }],
      disabled: false,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
    isGradeAvailable: true,
    selectConfig: {
      elementConfig: {
        type: "select",
        value: "",
        placeholder: "Select",
        tabIndex: 0,
        options: [{ label: "Select", value: "" }],
      },
    },
  },
  permissionToRepairWork: {
    wrapperClasses: ["form-element w-50 double-line m-right-15 m-top-0"],
    labelConfig: {
      label: "The permission for repair work should be obtained  from Owner",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      type: "select",
      value: "",
      placeholder:
        "The permission for repair work should be obtained  from Owner",
      tabIndex: 0,
      options: [{ label: "Select", value: "" }, ...dropdownYesNo()],
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  adjacentStores: {
    wrapperClasses: ["form-element w-50 double-line m-right-0 m-top-0"],
    labelConfig: {
      label: "Adjacent Stores should not be under Negative list",
      classes: ["label-title required-symbol"],
    },
    elementType: "select",
    elementConfig: {
      type: "select",
      value: "",
      placeholder: "Adjacent Stores should not be under Negative list",
      tabIndex: 0,
      options: [{ label: "Select", value: "" }, ...dropdownYesNo()],
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  recommendationCommentsHeader: {
    isHeader: "Recommendations Comments",
    formTypeStore: true,
  },
  strength: {
    wrapperClasses: ["form-element textarea-field m-top-0"],
    labelConfig: {
      label: "Strength of the Store Location",
      classes: ["label-title required-symbol"],
    },
    elementType: "textarea",
    elementConfig: {
      placeholder: "Strength",
      maxLength: INPUT_MAX_LENGTH.LENGTH_ONE_FIFTY,
      tabIndex: 0,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  weakness: {
    wrapperClasses: ["form-element textarea-field m-top-0"],
    labelConfig: {
      label: "Weakness of the Store Location",
      classes: ["label-title required-symbol"],
    },
    elementType: "textarea",
    elementConfig: {
      placeholder: "Weakness",
      maxLength: INPUT_MAX_LENGTH.LENGTH_ONE_FIFTY,
      tabIndex: 0,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  opportunity: {
    wrapperClasses: ["form-element textarea-field m-top-0"],
    labelConfig: {
      label: "Opportunity of the Store Location",
      classes: ["label-title required-symbol"],
    },
    elementType: "textarea",
    elementConfig: {
      placeholder: "Opportunity",
      maxLength: INPUT_MAX_LENGTH.LENGTH_ONE_FIFTY,
      tabIndex: 0,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  threat: {
    wrapperClasses: ["form-element textarea-field m-top-0"],
    labelConfig: {
      label: "Threat of the Store Location",
      classes: ["label-title required-symbol"],
    },
    elementType: "textarea",
    elementConfig: {
      placeholder: "Threat",
      maxLength: INPUT_MAX_LENGTH.LENGTH_ONE_FIFTY,
      tabIndex: 0,
    },
    value: "",
    errorMessage: "",
    valid: true,
    touched: false,
    formTypeStore: true,
  },
  // },
  storeEditable: false,
  storeEnable: false,
});

export const responseMapperForEvaluation = (data, currentForm, intentData, formattedStoreTemplates) => {
    const {appliedPartnership, businessDetail, storeEnable} = data;
    const {
        deliveryVehicle,
        partnerType,
        expectedTurnover,
        territoryCovered,
        shopArea,
        manpowerCapacity
    } = currentForm;
    const radioMapKeys = [
        {
            localKey: "shopOwnerShip",
            responseKey: "ownership",
            defaultValue: "RENTED"
        },
        {
            localKey: "wareHouseAvailable",
            responseKey: "warehouseAvailable",
            defaultValue: true
        },
        {
            localKey: "isCertifiedToOpen",
            responseKey: "isCertifiedToOpen",
            defaultValue: true
        },
        {
            localKey: "agreementWithStoreOwner",
            responseKey: "agreementWithStoreOwner",
            defaultValue: true
        },
        {
            localKey: "isInsideStore",
            responseKey: "isInsideStore",
            defaultValue: true
        },
        {
            localKey: "isStoreSurface",
            responseKey: "isStoreSurface",
            defaultValue: true
        },
        {
            localKey: "isRequiredLocalLanguage",
            responseKey: "isRequiredLocalLanguage",
            defaultValue: true
        },
        {
            localKey: "isFamilyInDTH",
            responseKey: "isFamilyInDTH",
            defaultValue: true
        }
    ];

    const {firmType:{radioConfig} } = currentForm;
    radioConfig.forEach(value => {
        value.elementConfig.checked = false;
    });
    let index = 0;
    if(data.firmType === 'PARTNER'){
        index = 1;
    }else if(data.firmType === 'COMPANY'){
        index = 2;
    }
    radioConfig[index].elementConfig.checked = true;

    radioMapKeys.forEach(element => {
        const {
            [element.localKey]: {radioConfig}
        } = currentForm;
        radioConfig.forEach(value => {
            value.elementConfig.checked = false;
        });
        let responseValue = false;
        switch(element.localKey){
            case 'shopOwnerShip':
            case 'wareHouseAvailable':
                responseValue = data[element.responseKey];
                break;
            case 'isCertifiedToOpen':
            case 'agreementWithStoreOwner':
                responseValue = data?.store?.storeDetails[element.responseKey];
                break;
            case 'isInsideStore':
            case 'isStoreSurface':
            case 'isRequiredLocalLanguage':
                responseValue = data?.store?.storeDimensionLayout[element.responseKey];
                break;
            case 'isFamilyInDTH':
                responseValue = data.store?.isFamilyInDTH;
                break;
            default:
                responseValue = true;
        }

        let index = responseValue === element.defaultValue ? 0 : 1;
        radioConfig[index].elementConfig.checked = true;
    });

    shopArea.value = data.shopArea || '';
    deliveryVehicle.value = (businessDetail && businessDetail.noOfDeliveryVehicle) || "";
    manpowerCapacity.value = (businessDetail && businessDetail.extendedManpowerCapacity) || "";

    if (appliedPartnership) {
        partnerType.value =
            (appliedPartnership.channel && appliedPartnership.channel.id) || '';
        territoryCovered.value = appliedPartnership.marketAreaCovered || '';
        expectedTurnover.value = numberFormatter(appliedPartnership.turnover) || '';
    }
    currentForm.storeEditable = data.storeEditable;
    currentForm.storeEnable = data.storeEnable;

    // for firm name
    currentForm.firmName = {
        ...currentForm.firmName,
        value: intentData.name || ""
    }
    // for town name
    currentForm.townName = {
        ...currentForm.townName,
        value: (intentData.address && intentData.address.town && intentData.address.town.townName) || ""
    }
    // for operation area
    currentForm.areaOperation = {
        ...currentForm.areaOperation,
        value: (intentData.address && intentData.address.town &&intentData.address.town.locationName) || ""
    }

    // for address line
    currentForm.firmAddress = {
        ...currentForm.firmAddress,
        value: (intentData.address && intentData.address.line) || ""
    };

    // for pinCode line
    currentForm.pinCode = {
        ...currentForm.pinCode,
        value:
            (intentData.address && intentData.address.pinCode && intentData.address.pinCode.code) || ""
    };
    const stateCityMapKey = {state: "id", city: "id", town: "id"};

    for (let key in stateCityMapKey) {
        currentForm[key] = {
            ...currentForm[key],
            value:
                (intentData.address &&
                    intentData.address[key] &&
                    intentData.address[key][stateCityMapKey[key]]) ||
                ""
        };
    }

    if(storeEnable && formattedStoreTemplates && formattedStoreTemplates.length) {
        for(let formKey in currentForm) {
            formattedStoreTemplates.forEach(template => {
                if(formKey ===  template.identifier) {
                    currentForm[formKey].elementConfig.options = template.optionsValue
                }
            });
        };
    }
    if(data.store && data.storeEnable) {
        currentForm.partnerName.value = data?.store?.partnerName;
        currentForm.familyDetails.value = data.store.familyDetails;
        currentForm.opportunity.value = data.store.opportunity;
        currentForm.weakness.value = data.store.weakness;
        currentForm.threat.value = data.store.threat;
        currentForm.strength.value = data.store.strength;
        currentForm.isFamilyInDTH.value = data.store.familyMemberRunningBusinessDetails;
        currentForm.numberOfMonths.value = data.store.storeDetails.numberOfMonths;
        currentForm.projectedAcquisition.value = data.store.storeDetails.projectedAcquisition;
        currentForm.projectedRecharge.value = data.store.storeDetails.projectedRecharge;
        currentForm.storeLocation.value = data.store.storeDetails.storeLocation;
        currentForm.distanceFromCurrentLocation.value = data.store.storeDimensionLayout.distanceFromCurrentLocation;
        currentForm.minimumFrontage.value = data.store.storeDimensionLayout.minimumFrontage;
        currentForm.nearbyImportantLandmark.value = data.store.storeDimensionLayout.nearbyImportantLandmark;
        currentForm.nearbyNumberOfStore.value = data.store.storeDimensionLayout.nearbyNumberOfStore;
        currentForm.nearbyPreferredStore.value = data.store.storeDimensionLayout.nearbyPreferredStore;
        currentForm.proposedDateOfHandover.value = (data.store.storeDimensionLayout.proposedDateOfHandover && data.store.storeDimensionLayout.proposedDateOfHandover != 0) ? new Date(data.store.storeDimensionLayout.proposedDateOfHandover).toISOString().slice(0, 10) : "";
        currentForm.proposedDateOfLaunch.value = (data.store.storeDimensionLayout.proposedDateOfLaunch && data.store.storeDimensionLayout.proposedDateOfLaunch != 0) ? new Date(data.store.storeDimensionLayout.proposedDateOfLaunch).toISOString().slice(0, 10) : "";
        currentForm.sizeOfStore.value = data.store.storeDimensionLayout.sizeOfStore;
        currentForm.activeOtherSubscriber.value = data.store.storeLocationDetails.activeOtherSubscriber;
        currentForm.activeSubscriber.value = data.store.storeLocationDetails.activeSubscriber;
        currentForm.allClusterName.value = data.store.storeLocationDetails.allClusterName;
        currentForm.monthlyCableSubscription.value = data.store.storeLocationDetails.monthlyCableSubscription;
        currentForm.numberOfHomepass.value = data.store.storeLocationDetails.numberOfHomepass || "";
        currentForm.numberOfHouseholds.value = data.store.storeLocationDetails.numberOfHouseholds;
        currentForm.rechargeBase.value = data.store.storeLocationDetails.rechargeBase;
        currentForm.populationOfLocality.value = data.store.storeLocationDetails.populationOfLocality;
        currentForm.adjacentStores.value = data.store.storePropertyDetails.adjacentStores;
        currentForm.electricityConnectionCapacity.value = data.store.storePropertyDetails.electricityConnectionCapacity;
        currentForm.leakageIssue.value = data.store.storePropertyDetails.leakageIssue;
        currentForm.legalDisputeNOC.value = data.store.storePropertyDetails.legalDisputeNOC;
        currentForm.monthlyRented.value = data.store.storePropertyDetails.monthlyRented;
        currentForm.noticeFromLocalAuthority.value = data.store.storePropertyDetails.noticeFromLocalAuthority;
        currentForm.permissionToRepairWork.value = data.store.storePropertyDetails.permissionToRepairWork;
        currentForm.prevThreeYearsDetail.value = data.store.storePropertyDetails.prevThreeYearsDetail;
        currentForm.securityDeposit.value = data.store.storePropertyDetails.securityDeposit;
        currentForm.storeLocationInLCO.value = data.store.storePropertyDetails.storeLocationInLCO;
        currentForm.waterAccumulationIssue.value = data.store.storePropertyDetails.waterAccumulationIssue;
        currentForm.yearlyRental.value = data.store.storePropertyDetails.yearlyRental;
        data.store.storeGradeValues.forEach(template => {
            currentForm[template.storeTemplate.customTemplateIdentifier].value = template?.storeTemplateTypeValues[0]?.optionKey?.id;
            if(template?.storeTemplateTypeValues[0]?.optionKey?.disabledFields) {
                const disabledFields = template?.storeTemplateTypeValues[0]?.optionKey?.disabledFields?.split(',');
                disabledFields.forEach(field => {
                    currentForm[field].elementConfig.disabled = true;
                })
            }
;        })
        data.store.storeLocationDetails.activeBasePincodes.split(',').forEach((pin, index) => {
            if (index === 0) {
                currentForm.pinCodes[index].pinCodes.value = pin;
            } else {
                const newPinCodeObj = DefaultPincodesFormObj()
                newPinCodeObj.pinCodes.value = pin;
                currentForm.pinCodes.push(newPinCodeObj);
            }
        })
        const descriptionOptions = storeDescriptionList();
        const description = descriptionOptions.filter(option => option.label == data.store.storeDimensionLayout.briefDescription);
        if (description.length > 0 && description[0].label != "Others") {
            currentForm.briefDescription.value = description[0].value;
        } else {
            currentForm.briefDescription.value = 4;
            currentForm.otherDescription.value = data.store.storeDimensionLayout.briefDescription;
            currentForm.otherDescription.elementConfig.disabled = false;
        }
    }
    return currentForm;
};

export const checkPartnerTypeToSaveAsDraft = (currentForm, buttonType) => {
    if(currentForm.partnerType.value) {
        return {id: currentForm.partnerType.value}
    }
    if(currentForm.partnerType.value === '' && !buttonType.validate) {
        return null
    }
}

export const requestMapperForEvaluation = (currentForm, formIds, buttonType, storeTemplates) => {
    const {intentFormIds, evalFormIds} = formIds;
    let businessAssociationsList = [];
    currentForm.currentBusinessAssociation.map(item => {
        if (item.curAssoc.value && item.curAssoc.selectConfig.elementConfig.value) {
            businessAssociationsList.push({
                id: item.id.value || '',
                digitalService: {
                    id: item.curAssoc.selectConfig.elementConfig.value || ''
                },
                company: item.curAssoc.value || '',
                marketAreaCovered: item.marketArea.value || '',
                sinceYear: item.since.value || '',
                sisterConcern: item.sisterConcern.value || '',
                remarks: item.remark.value || ''
            });
        }
    });
    let bankList = [];
    currentForm.bankDetails.map(item => {
        if (item.bank.value ) {
            bankList.push({
                // id: item.id.value || '',
                id: item.id || '',
                bank: {
                    id: item.bank.value || '',
                },
                name: item.bank.isOther ? item.otherBank.value || '' :''
            });
        }
    });
    let firmTypeValue = 'PROPRIETOR';
    const {firmType:{radioConfig} } = currentForm;
    radioConfig.forEach(value => {
        if(value.elementConfig.checked){
            firmTypeValue = value.elementConfig.value
        }
    });

    let responseForm = {
        firm: {
            id: intentFormIds.firmId || '',
            firmType: firmTypeValue,
            ownership: currentForm.shopOwnerShip.radioConfig[0].elementConfig.checked ? "RENTED" : "OWNED",
            shopArea: currentForm.shopArea.value || '',
            warehouseAvailable: currentForm.wareHouseAvailable.radioConfig[0].elementConfig.checked,
            areaUnits: null,
            banks: bankList
        },
        businessDetail: {
            id: evalFormIds.businessDetailsId || "",
            noOfDeliveryVehicle: currentForm.deliveryVehicle.value || '',
            extendedManpowerCapacity: currentForm.manpowerCapacity.value || '',
            businessAssociations:
                businessAssociationsList && businessAssociationsList.length
                    ? businessAssociationsList
                    : []
        },
        appliedPartnership: {
            id: evalFormIds.appliedPartnershipId || '',
            channel: checkPartnerTypeToSaveAsDraft(currentForm, buttonType),
            marketAreaCovered: currentForm.territoryCovered.value || '',
            turnover: (currentForm.expectedTurnover.value && currentForm.expectedTurnover.value.replace(/,/g,'')) || ''
        },
        store: currentForm.storeEnable ? storeRequestMapper(currentForm, storeTemplates) : null,
        actionId: (buttonType && buttonType.id) || '',
        // evaluationFormPrivacy: currentForm.termsAndCondition.value || false,

    };
    return responseForm;
};

const storeRequestMapper = (currentForm, storeTemplates) => {
    let activePincodes;
    if(currentForm.pinCodes.length) {
        let outputPincodes = [];
        currentForm.pinCodes.map(item => {
            outputPincodes.push(item.pinCodes.value);
        });
        activePincodes = outputPincodes.toString();
    }

    const store = {
        partnerName: currentForm.partnerName.value,
        familyDetails: currentForm.familyDetails.value,
        familyMemberRunningBusinessDetails: currentForm.isFamilyInDTH.value,
        id: "",
        isFamilyInDTH: currentForm.isFamilyInDTH.radioConfig[0].elementConfig.checked,
        opportunity: currentForm.opportunity.value,
        storeDetails: {
            agreementWithStoreOwner: currentForm.agreementWithStoreOwner.radioConfig[0].elementConfig.checked,
            id: "",
            isCertifiedToOpen: currentForm.isCertifiedToOpen.radioConfig[0].elementConfig.checked,
            numberOfMonths: currentForm.numberOfMonths.value,
            projectedAcquisition: currentForm.projectedAcquisition.value,
            projectedRecharge: currentForm.projectedRecharge.value,
            storeLocation: currentForm.storeLocation.value,
        },
        storeDimensionLayout: {
            // briefDescription: currentForm.briefDescription.value != "4" ? currentForm.briefDescription.value : currentForm.otherDescription.value,
            distanceFromCurrentLocation: currentForm.distanceFromCurrentLocation.value,
            id: "",
            isInsideStore: currentForm.isInsideStore.radioConfig[0].elementConfig.checked,
            isRequiredLocalLanguage: currentForm.isRequiredLocalLanguage.radioConfig[0].elementConfig.checked,
            isStoreSurface: currentForm.isStoreSurface.radioConfig[0].elementConfig.checked,
            minimumFrontage: currentForm.minimumFrontage.value,
            nearbyImportantLandmark: currentForm.nearbyImportantLandmark.value,
            nearbyNumberOfStore: currentForm.nearbyNumberOfStore.value,
            nearbyPreferredStore: currentForm.nearbyPreferredStore.value,
            proposedDateOfHandover: currentForm.proposedDateOfHandover.value,
            proposedDateOfLaunch: currentForm.proposedDateOfLaunch.value,
            sizeOfStore: currentForm.sizeOfStore.value
        },
        storeGradeValues: getStoreGradeValues(currentForm, storeTemplates),
        storeLocationDetails: {
            activeOtherSubscriber: currentForm.activeOtherSubscriber.value,
            activeSubscriber: currentForm.activeSubscriber.value,
            allClusterName: currentForm.allClusterName.value,
            id: "",
            monthlyCableSubscription: currentForm.monthlyCableSubscription.value,
            numberOfHomepass: currentForm.numberOfHomepass.value,
            numberOfHouseholds: currentForm.numberOfHouseholds.value,
            populationOfLocality: currentForm.populationOfLocality.value,
            rechargeBase: currentForm.rechargeBase.value,
            activeBasePincodes: activePincodes ? activePincodes : "",
        },
        storePropertyDetails: {
            adjacentStores: currentForm.adjacentStores.value,
            electricityConnectionCapacity: currentForm.electricityConnectionCapacity.value,
            id: "",
            leakageIssue: currentForm.leakageIssue.value,
            legalDisputeNOC: currentForm.legalDisputeNOC.value,
            monthlyRented: currentForm.monthlyRented.value,
            noticeFromLocalAuthority: currentForm.noticeFromLocalAuthority.value,
            permissionToRepairWork: currentForm.permissionToRepairWork.value,
            prevThreeYearsDetail: currentForm.prevThreeYearsDetail.value,
            securityDeposit: currentForm.securityDeposit.value,
            storeLocationInLCO: currentForm.storeLocationInLCO.value,
            waterAccumulationIssue: currentForm.waterAccumulationIssue.value,
            yearlyRental: currentForm.yearlyRental.value
        },
        strength: currentForm.strength.value,
        threat: currentForm.threat.value,
        totalStoreScore: getTotalStoreScore(currentForm),
        weakness: currentForm.weakness.value
    }
    const descriptionOptions = storeDescriptionList();
    const description = descriptionOptions.filter(option => option.value == currentForm.briefDescription.value);
    if(description.length > 0 && description[0].label === 'Others') {
        store.storeDimensionLayout.briefDescription = currentForm.otherDescription.value;
    } else {
        store.storeDimensionLayout.briefDescription = description[0].label;
    }
    return store;
}

const getTotalStoreScore = (currentForm) => {
    let totalScore = 0;
    for(let formKey in currentForm) {
        if(currentForm[formKey].isGradeAvailable) {
            let option = currentForm[formKey].elementConfig.options.filter(option => option.value == currentForm[formKey].value)[0];
            if(option) {
                totalScore += parseFloat(option.points);
            }
        }
    }
    return totalScore;
}

const getStoreGradeValues = (currentForm, storeTemplates) => {
    let storeForm = [];
    for(let formKey in currentForm) {
        if(currentForm[formKey].isGradeAvailable) {
            storeTemplates.forEach(template => {
                if(formKey === template.customTemplateIdentifier) {
                    let storeGrade = {
                        // createdDate: new Date(),
                        id: "",
                        storeTemplate : template,
                        storeTemplateTypeValues: [
                            {
                                // createdDate: new Date(),
                                id: "",
                                optionKey: template.templateTypeKeys[0].optionKeys.filter(option => option.id == currentForm[formKey].value)[0],
                                templateTypeKey: template.templateTypeKeys[0]
                            }
                        ]
                    }
                    storeForm.push(storeGrade);
                }
            })
        }
    }
    return storeForm;
}

export const DefaultDocumentSoftCopyForm = () => ({
    warehouseDetails: {
        isHeader: "Warehouse Details",
    },
    totalArea: {
        wrapperClasses: ["form-element w-50"],
        labelConfig: {
            label: "Warehouse Total Area",
            classes: ["label-title required-symbol"],
            optional: ["(sq ft)"]
        },
        elementType: "input",
        elementConfig: {
            type: "tel",
            placeholder: "Warehouse Total Area",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTY,
            tabIndex: 0,
            disabled: false
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false
    },
    managerName: {
        wrapperClasses: ["form-element w-50"],
        labelConfig: {
            label: "Warehouse Manager Name",
            classes: ["label-title required-symbol"]
        },
        elementType: "input",
        elementConfig: {
            type: "text",
            placeholder: "Warehouse Manager Name",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTY,
            tabIndex: 0,
            disabled: false
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false

    },
    managerMobileNo: {
        wrapperClasses: ["form-element w-50"],
        labelConfig: {
            label: "Warehouse Manager Mobile",
            classes: ["label-title required-symbol"]
        },
        elementType: "input",
        elementConfig: {
            type: "tel",
            placeholder: "Warehouse Manager Mobile",
            maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
            tabIndex: 0,
            disabled: false
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false

    },
    address: {
        wrapperClasses: ["form-element textarea-field"],
        labelConfig: {
            label: "Address",
            classes: ["label-title required-symbol"]
        },
        elementType: "textarea",
        elementConfig: {
            placeholder: "Address",
            maxLength: INPUT_MAX_LENGTH.LENGTH_ONE_FIFTY,
            tabIndex: 0,
            disabled: false
        },
        id: '',
        value: "",
        errorMessage: "",
        valid: true,
        touched: false
    },
    pinCode: {
        wrapperClasses: ["form-element w-33"],
        labelConfig: {
            label: "Pin Code",
            classes: ["label-title required-symbol"]
        },
        elementType: "input",
        elementConfig: {
            type: "number",
            placeholder: "Pin Code",
            maxLength: INPUT_MAX_LENGTH.LENGTH_SIX,
            tabIndex: 0,
            disabled: false,
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false
    },
    town: {
        wrapperClasses: ["form-element w-33"],
        labelConfig: {
            label: "Town-Location",
            classes: ["label-title required-symbol"]
        },
        elementType: "customSelect",
        elementConfig: {
            placeholder: "Town-Location",
            tabIndex: 0,
            options: [{label: "Select", value: ""}],
            disabled: false
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false
    },
    city: {
        wrapperClasses: ["form-element select-control w-33 d-none"],
        labelConfig: {
            label: "city",
            classes: ["label-title required-symbol"]
        },
        elementType: "select",
        elementConfig: {
            placeholder: "city",
            tabIndex: 0,
            options: [{label: "Select", value: ""}],
            disabled: true
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false
    },
    state: {
        wrapperClasses: ["form-element select-control w-33 no-select-bar"],
        labelConfig: {
            label: "State",
            classes: ["label-title required-symbol"]
        },
        elementType: "select",
        elementConfig: {
            type: "text",
            placeholder: "State",
            tabIndex: 0,
            options: [],
            disabled: true
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false
    },
    evdDetailsHeading: {
        isHeader: "EVD Details",
    },
    evdMobileNo: {
        wrapperClasses: ["form-element w-50"],
        labelConfig: {
            label: "EVD Mobile",
            classes: ["label-title required-symbol"]
        },
        elementType: "input",
        elementConfig: {
            type: "tel",
            placeholder: "EVD Mobile",
            maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
            tabIndex: 0,
            disabled: false
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false

    },
    evdEmailAddress: {
        wrapperClasses: ["form-element w-50"],
        labelConfig: {
            label: "Email Address",
            classes: ["label-title required-symbol"]
        },
        elementType: "input",
        elementConfig: {
            type: "text",
            placeholder: "Email Address",
            maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTY,
            tabIndex: 0,
            disabled: false
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false
    },
    partnerDetailsHeading: {
        isHeader: "Applicant's Partner's Details",
    },
    partnerDetails: [
        {
            id: "",
            partnerName: {
                wrapperClasses: ["form-element w-37"],
                labelConfig: {
                    label: "Applicant's Partner's Name",
                    classes: ["label-title required-symbol"]
                },
                elementType: "input",
                elementConfig: {
                    type: "text",
                    placeholder: "Applicant's Partner's Name",
                    maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTY,
                    tabIndex: 0,
                    disabled: false
                },
                value: "",
                errorMessage: "",
                valid: true,
                touched: false
            },
            partnerPanNumber: {
                wrapperClasses: ["form-element w-37"],
                labelConfig: {
                    label: "PAN Of Partner",
                    classes: ["label-title required-symbol"]
                },
                elementType: "input",
                elementConfig: {
                    type: "text",
                    placeholder: "PAN Of Partner",
                    maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
                    tabIndex: 0,
                    disabled: false
                },
                value: "",
                errorMessage: "",
                valid: true,
                touched: false
            },
            buttonConfig: {
                isButton: true,
                type: 'More',
                text: '+',
                buttonClass: 'add-btn'
            },
        }
    ],
    bankDetailsHeading: {
        isHeader: 'Bank Details'
    },
    bankDetails: [
        {
            id: "",
            bankName: {
                wrapperClasses: ["form-element select-control w-25"],
                labelConfig: {
                    label: "Bank Name",
                    classes: ["label-title required-symbol"]
                },
                elementType: "select",
                elementConfig: {
                    type: "text",
                    placeholder: "Bank Name",
                    tabIndex: 0,
                    options: [{label: "Select", value: ""}],
                    disabled: false
                },
                value: "",
                errorMessage: "",
                valid: true,
                touched: false,
                isOther: false
            },
            otherBank: {
                wrapperClasses: ["form-element w-25"],
                labelConfig: {
                    label: "Name",
                    classes: ["label-title required-symbol"]
                },
                elementType: "input",
                elementConfig: {
                    type: "text",
                    placeholder: "Name",
                    maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTY,
                    tabIndex: 0,
                    disabled: false
                },
                value: "",
                errorMessage: "",
                valid: true,
                touched: false
            },
            bankAccountNumber: {
                wrapperClasses: ["form-element w-25"],
                labelConfig: {
                    label: "Account No.",
                    classes: ["label-title required-symbol"]
                },
                elementType: "input",
                elementConfig: {
                    type: "text",
                    placeholder: "Account No.",
                    maxLength: INPUT_MAX_LENGTH.LENGTH_SIXTEEN,
                    tabIndex: 0,
                    disabled: false
                },
                value: "",
                errorMessage: "",
                valid: true,
                touched: false
            },
            ccLimit: {
                wrapperClasses: ["form-element w-25"],
                labelConfig: {
                    label: "CC Limit",
                    classes: ["label-title"]
                },
                elementType: "input",
                elementConfig: {
                    type: "text",
                    placeholder: "CC Limit",
                    maxLength: INPUT_MAX_LENGTH.LENGTH_NINE,
                    tabIndex: 0,
                    disabled: false
                },
                value: "",
                errorMessage: "",
                valid: true,
                touched: false
            },
            buttonConfig: {
                isButton: true,
                type: 'More',
                text: '+',
                buttonClass: 'add-btn mb10'
            },
        },
    ],
    docDetailsHeader: {
        isHeader: "UPLOAD DOCUMENTS"
    },
    docDetailsSubHeader: {
        isSubHeader: '(File size upto 25MB recommended)'
    },
    uploadDocumentsTemplate: {
        isTemplate: true,
        id: '',
        templateArr: [],
    },
    termsAndCondition:{
        isTermsAndCondition: true,
        value: false,
        valid: true,
        errorMessage:''
    }
});

export const responseMapperForDocumentSoftCopy = (data, currentForm, partner) => {
    currentForm.totalArea = {
        ...currentForm.totalArea,
        value: (data.wareHouse && data.wareHouse.totalArea) || ""
    };
    currentForm.managerName = {
        ...currentForm.managerName,
        value: (data.wareHouse && data.wareHouse.managerName) || ""
    };
    currentForm.managerMobileNo = {
        ...currentForm.managerMobileNo,
        value: (data.wareHouse && data.wareHouse.managerMobileNo) || ""
    };
    // for address line
    currentForm.address = {
        ...currentForm.address,
        value: (data.wareHouse && data.wareHouse.address && data.wareHouse.address.line) || ""
    };
    // for EVD mobile
    currentForm.evdMobileNo = {
        ...currentForm.evdMobileNo,
        value: (data && data.evdPhoneNumber) || ""
    };
    // for EVD Email
    currentForm.evdEmailAddress = {
        ...currentForm.evdEmailAddress,
        value: (data && data.evdEmailId) || ""
    };

    // for pinCode line
    currentForm.pinCode = {
        ...currentForm.pinCode,
        value:
            (data.wareHouse && data.wareHouse.address && data.wareHouse.address.pinCode &&
                data.wareHouse.address.pinCode.code) || ""
    };

    const stateCityMapKey = {state: "id", city: "id", town: "id"};

    for (let key in stateCityMapKey) {
        currentForm[key] = {
            ...currentForm[key],
            value:
                (data.wareHouse && data.wareHouse.address &&
                    data.wareHouse.address[key] &&
                    data.wareHouse.address[key][stateCityMapKey[key]]) ||
                ""
        };
    }
    if (partner.firm.warehouseAvailable) {
        currentForm.evdMobileNo = {
            ...currentForm.evdMobileNo,
            wrapperClasses: ["form-element w-50 m-margin-15"]
        };
        currentForm.evdEmailAddress = {
            ...currentForm.evdEmailAddress,
            wrapperClasses: ["form-element w-50 m-margin-0"]
        };
    }
    return currentForm;
};

export const getUploadDocumentSelectedRadioVals = (data) => {
    let uploadedRadioValueKeys = [];
    if (data && data.partnerDocument) {
        data.partnerDocument.uploadedDocuments.forEach(docs => {
            docs.templateTypeValue.forEach(tempVal => {
                uploadedRadioValueKeys.push({id:tempVal.radioKey.id,keyName:tempVal.radioKey.keyName});
            })
        });
    }
    return uploadedRadioValueKeys;
};

export const requestMapperForDocumentSoftCopy = (currentForm, formIds, serverPartnerObj, buttonType) => {
    let reqObj = {
        firm: {
            wareHouse: {
                id: formIds.wareHouseId || "",
                address: {
                    id: formIds.wareHouseAddressId || "",
                    line: currentForm.address.value || "",
                    pinCode: {
                        code: currentForm.pinCode.value || ""
                    },
                    state: {
                        id: currentForm.state.value || ""
                    },
                    city: {
                        id: currentForm.city.value || "",
                        state: {
                            id: currentForm.state.value || ""
                        }
                    },
                    town: {
                        id: currentForm.town.value || ""
                    },
                },
                managerMobileNo: currentForm.managerMobileNo.value,
                managerName: currentForm.managerName.value,
                totalArea: currentForm.totalArea.value,
            },
            banks: [],
            evdEmailId:currentForm.evdEmailAddress.value,
            evdPhoneNumber:currentForm.evdMobileNo.value,
        },
        partnerDetails: [],
        partnerDocument: {
            id: formIds.partnerDocumentId || "",
            uploadedDocuments: []
        },
        actionId: (buttonType && buttonType.id) || '',
        documentFormPrivacy: currentForm.termsAndCondition.value || false,

    };
    for (let i = 0; i < currentForm.partnerDetails.length; i++) {
        let partnerObj = currentForm.partnerDetails[i];
        reqObj['partnerDetails'].push({
            id: partnerObj.id || "",
            name: partnerObj['partnerName'].value,
            pan: partnerObj['partnerPanNumber'].value
        })

    }
    for (let i = 0; i < currentForm.bankDetails.length; i++) {
        let bankObj = currentForm.bankDetails[i];
        reqObj['firm']['banks'].push({
            id: bankObj.id || "",
            bank: {
                id: bankObj['bankName'].value,
            },
            name: bankObj["bankName"].isOther ? bankObj["otherBank"].value || '' :'',
            ccLimit: bankObj['ccLimit'].value || '',
            accountNo: bankObj['bankAccountNumber'].value

        })
    }

    for (let i = 0; i < currentForm.uploadDocumentsTemplate.templateArr.length; i++) {
        let documentObj = currentForm.uploadDocumentsTemplate.templateArr[i];
        let uploadDocumentObj = {
            id: "",
            templateTypeValue: [],
            documentTemplate: {},
            documents: [],
            rightSectionCheckBox: null
        };

        if (documentObj.rightSectionConfig) {
            /*uploadDocumentObj['rightSectionCheckBox'] = {
                value: documentObj.rightSectionConfig.checked,
                id: documentObj.rightSectionConfig.id || ""
            };*/
            uploadDocumentObj['rightSectionCheckBox'] = documentObj.rightSectionConfig.checked;
        }

        if (serverPartnerObj && serverPartnerObj.partnerDocument && serverPartnerObj.partnerDocument.uploadedDocuments.length) {
            for (let j = 0; j < serverPartnerObj.partnerDocument.uploadedDocuments.length; j++) {
                let partnerObj = serverPartnerObj.partnerDocument.uploadedDocuments[j];
                if (partnerObj.documentTemplate.id === documentObj.id) {
                    uploadDocumentObj['id'] = partnerObj.id;
                    if(partnerObj.documentStatus === 'REJECT') {
                        uploadDocumentObj.documentStatus = "UNACTION";
                        uploadDocumentObj.comment = "";
                    } else if(partnerObj.documentStatus === 'APRROVED') {
                        uploadDocumentObj.documentStatus = "APRROVED";
                    }
                }
            }
        }

        uploadDocumentObj['documentTemplate']['id'] = documentObj.id;
        if (documentObj.radioElementArr) {
            for (let j = 0; j < documentObj.radioElementArr.length; j++) {
                let radioObj = documentObj.radioElementArr[j];
                let radioKey = {};
                radioObj && radioObj.radioConfig.forEach(value => {
                    if (value.elementConfig.checked) {
                        radioKey['id'] = value.elementConfig.value;
                    }
                });
                if (radioObj.visible && radioKey.id) {
                    uploadDocumentObj['templateTypeValue'].push({
                        templateTypeKey: {id: radioObj.id},
                        radioKey: {...radioKey}
                    });
                }
            }
        }
        if (documentObj.docList.length) {
            documentObj.docList.forEach(obj => {
                uploadDocumentObj['documents'].push({
                    id: obj.id
                })
            })
        }
        reqObj['partnerDocument']['uploadedDocuments'].push(uploadDocumentObj);
    }
    return reqObj;
};


export const DefaultDocHardCopyState = () => ({
    hardCopyDataList: [
        {
            heading: "List of documents needs to be couriered",
            description: "Make sure the orignal document should match with the softcopy you have shared earlier.",
            list: ["Legal  Agreement", "Blank Cheque / Bank Guarantee"]
        },
        {
            heading: "Send To",
            subHeading: "",
            description: "",
        },
    ],
    partnerCourier : {},
    trackId: {
        wrapperClasses: ['form-element track-input'],
        labelConfig: {
          label: 'Enter Tracking ID',
          classes: ['floating-label required-symbol'],
        },
        elementType: 'input',
        elementConfig: {
          type: 'tel',
          placeholder: '',
          maxLength: INPUT_MAX_LENGTH.LENGTH_TWENTY_FOUR,
          tabIndex: 0,
        },
        value: '',
        errorMessage: '',
        valid: true,
    },
    hardCopyUpload: {
        wrapperClasses: ['form-element hardcopy-upload'],
        labelConfig: {
          label: 'Click photo of courier and upload it ',
          classes: ['floating-label required-symbol'],
        },
        elementType: 'file',
        elementConfig: {
          type: 'file',
          placeholder: '',
          tabIndex: 0,
          accept: "image/pdf, image/jpeg, image/png"
        },
        value: '',
        errorMessage: '',
        valid: true,
        docList: [],
  },
  termsAndCondition:{
    isTermsAndCondition: true,
    value: false,
    valid: true,
    errorMessage:''
  }
});

export const responseMapperForDocHardCopy = (data, currentForm) => {
    const sendToType = data.sendToType === ROLES_CATEGORY.PARTNER ? ROLES_CATEGORY.PARTNER : "MIS";
    const courierTypeKey = sendToType === ROLES_CATEGORY.PARTNER ? "partnerCourier" : "misCourier";
    let subHeading = '';
    let description ='';
    let addLine = '';
    let city = '';
    let state = '';
    let pinCode = '';
    if(data.courier && data.courier[courierTypeKey] && data.courier[courierTypeKey].sendTo){
        subHeading = data.courier[courierTypeKey].sendTo.name || '';
        if(data.courier[courierTypeKey].sendTo.address){
           addLine = data.courier[courierTypeKey].sendTo.address.line || '';
            if(data.courier[courierTypeKey].sendTo.address.city){
                city= data.courier[courierTypeKey].sendTo.address.city.name || '';
            }
            if(data.courier[courierTypeKey].sendTo.address.state){
                state = data.courier[courierTypeKey].sendTo.address.state.name || '';
            }
            if(data.courier[courierTypeKey].sendTo.address.pinCode){
                pinCode = data.courier[courierTypeKey].sendTo.address.pinCode.code || '';
            }
        }
    }
    description = (addLine && city && state && pinCode) ? addLine + ', '+city+ ', '+state+' - '+pinCode : '';
    currentForm.hardCopyDataList[1].subHeading = subHeading;
    currentForm.hardCopyDataList[1].description = description;
    if(courierTypeKey === "partnerCourier") {
        currentForm.trackId.value = (data.courier && data.courier[courierTypeKey] && data.courier[courierTypeKey].trackingId) || '';
    } else {
        currentForm['partnerCourier'].trackId = (data.courier && data.courier["partnerCourier"] && data.courier["partnerCourier"].trackingId) || '';
    }


    let doc = {};
      if(data.courier && data.courier['partnerCourier'] && data.courier['partnerCourier'].document && data.courier['partnerCourier'].document.length){
        doc = {...data.courier['partnerCourier'].document[0]};
          if(courierTypeKey === "partnerCourier") {
              currentForm.hardCopyUpload.docList.push(doc);
          } else {
              currentForm['partnerCourier'].docList = [];
              currentForm['partnerCourier'].docList.push(doc);
          }
    }
    return currentForm;
};

export const requestMapperForDocHardCopy = (currentForm, formIds, buttonType) => {
    let courierTypeKey = formIds.sendToType === ROLES_CATEGORY.PARTNER ? 'partnerCourier':'misCourier';
    let responseForm = {
        courier:{
            [courierTypeKey]:{
                trackingId: currentForm.trackId.value || '',
                document: (currentForm.hardCopyUpload.docList && currentForm.hardCopyUpload.docList.length) ? currentForm.hardCopyUpload.docList : []
            }
        },
        actionId: (buttonType && buttonType.id) || '',
      // documentFormPrivacy: currentForm.termsAndCondition.value || false,
    };
    return responseForm;
};

// export const dummyHardCopyResponse = () => ({
//     "sendTo": {
//       "id": 11,
//       "email": "shobhitg04@gmail.com",
//       "mobileNumber": "9540426862",
//       "name": null,
//       "regionRole": null,
//       "entity_org_code": null,
//       "circle_code": null,
//       "mobileNumberVerified": true,
//       "emailVerified": true,
//       "address": {
//         "id": 15,
//         "line": "",
//         "city": {
//           "id": 1,
//           "name": "Kanpur",
//           "state": {
//             "id": 1,
//             "name": "Uttar Pradesh"
//           }
//         },
//         "state": {
//           "id": 1,
//           "name": "Uttar Pradesh"
//         },
//         "pinCode": {
//           "id": 1,
//           "code": "201301"
//         }
//       }
//     },
//     "sendToType": "PARTNER",
//     "courier": {
//       "id": 1,
//       "partnerCourier": {
//         "id": 1,
//         "sendTo": {
//           "id": 10,
//           "email": "shobhitghdhdhd04@gmail.com",
//           "mobileNumber": "9540426867",
//           "name": "S S Sharma",
//           "regionRole": null,
//           "entity_org_code": null,
//           "circle_code": null,
//           "mobileNumberVerified": true,
//           "emailVerified": true,
//           "address": {
//             "id": 15,
//             "line": "Tata Sky Office, Tower 1, Umiya Business Bay,  Cessna Business Park, Varthur Hobli",
//             "city": {
//               "id": 1,
//               "name": "Kanpur",
//               "state": {
//                 "id": 1,
//                 "name": "Uttar Pradesh"
//               }
//             },
//             "state": {
//               "id": 1,
//               "name": "Uttar Pradesh"
//             },
//             "pinCode": {
//               "id": 1,
//               "code": "201301"
//             }
//           }
//         },
//         "trackingId": "dfdfdfdfd",
//         "document": [{
//             id: 107,
// name: "gst.png",
// url: "https://dev-ts-onboarding.s3.ap-south-1.amazonaws.com/15801894426928800990167-gst.png"
//         }]
//       },
//       "misCourier": {
//         "id": 1,
//         "sendTo": {
//           "id": 11,
//           "email": "shobhitg04@gmail.com",
//           "mobileNumber": "9540426862",
//           "name": null,
//           "regionRole": null,
//           "entity_org_code": null,
//           "circle_code": null,
//           "mobileNumberVerified": true,
//           "emailVerified": true,
//           "address": {
//             "id": 15,
//             "line": "Tata Sky Office, Tower 1, Umiya Business Bay,  Cessna Business Park, Varthur Hobli",
//             "city": {
//               "id": 1,
//               "name": "Kanpur",
//               "state": {
//                 "id": 1,
//                 "name": "Uttar Pradesh"
//               }
//             },
//             "state": {
//               "id": 1,
//               "name": "Uttar Pradesh"
//             },
//             "pinCode": {
//               "id": 1,
//               "code": "201301"
//             }
//           }
//         },
//         "trackingId": "dfdfdfdfd",
//         "document": [{
//             id: 107,
// name: "gst.png",
// url: "https://dev-ts-onboarding.s3.ap-south-1.amazonaws.com/15801894426928800990167-gst.png"
//         }]
//       },
//       "partnerTransactionRequest": null
//     }
//   });

export const getNewPartnerObject = (partnerDetails) => {
    return {
        id: partnerDetails.id,
        partnerName: {
            wrapperClasses: ["form-element multiline-error w-37"],
            labelConfig: {
                label: "Applicant Name",
                classes: ["label-title"]
            },
            elementType: "input",
            elementConfig: {
                type: "text",
                placeholder: "Applicant Name",
                maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTY,
                tabIndex: 0,
                disabled: false
            },
            value: `${partnerDetails.name}`,
            errorMessage: "",
            valid: true,
            touched: false
        },
        partnerPanNumber: {
            wrapperClasses: ["form-element multiline-error w-37"],
            labelConfig: {
                label: "PAN Of Applicant",
                classes: ["label-title"]
            },
            elementType: "input",
            elementConfig: {
                type: "text",
                placeholder: "PAN Of Applicant",
                maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
                tabIndex: 0,
                disabled: false
            },
            value: `${partnerDetails.pan}`,
            errorMessage: "",
            valid: true,
            touched: false
        },
        buttonConfig: {
            isButton: true,
            type: "Remove",
            text: "-",
            buttonClass: "add-btn remove"
        }
    }
};

export const getNewBankObject = (bankDetails, bankNameOptions) => {
    return {
        id: bankDetails.id,
        bankName: {
            wrapperClasses: ["form-element select-control w-25"],
            labelConfig: {
                label: "Bank Name",
                classes: ["label-title"]
            },
            elementType: "select",
            elementConfig: {
                type: "text",
                placeholder: "Bank Name",
                tabIndex: 0,
                options: [...bankNameOptions],
                disabled: false
            },
            value: `${bankDetails.bank.id}`,
            errorMessage: "",
            valid: true,
            touched: false,
            isOther: false
        },
        otherBank: {
            wrapperClasses: ["form-element w-25"],
            labelConfig: {
                label: "Name",
                classes: ["label-title required-symbol"]
            },
            elementType: "input",
            elementConfig: {
                type: "text",
                placeholder: "Name",
                maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTY,
                tabIndex: 0,
                disabled: false
            },
            value: "",
            errorMessage: "",
            valid: true,
            touched: false
        },
        bankAccountNumber: {
            wrapperClasses: ["form-element w-25"],
            labelConfig: {
                label: "Account No.",
                classes: ["label-title required-symbol"]
            },
            elementType: "input",
            elementConfig: {
                type: "text",
                placeholder: "Account No.",
                maxLength: INPUT_MAX_LENGTH.LENGTH_SIXTEEN,
                tabIndex: 0,
                disabled: false
            },
            value: `${bankDetails.accountNo}`,
            errorMessage: "",
            valid: true,
            touched: false
        },
        ccLimit: {
            wrapperClasses: ["form-element w-25"],
            labelConfig: {
                label: "CC Limit",
                classes: ["label-title"]
            },
            elementType: "input",
            elementConfig: {
                type: "text",
                placeholder: "CC Limit",
                maxLength: INPUT_MAX_LENGTH.LENGTH_TWENTY_FOUR,
                tabIndex: 0,
                disabled: false
            },
            value: `${bankDetails.ccLimit}`,
            errorMessage: "",
            valid: true,
            touched: false
        },
        buttonConfig: {
            isButton: true,
            type: "Remove",
            text: "-",
            buttonClass: "add-btn mb10 remove"
        }
    };
};

export const formKeys = {
    INTENT: {
        activeTab: 0,
        apiKey: 'intentForm',
    },
    EVALUATION: {
        activeTab: 1,
        apiKey: 'evaluationForm',
    },
    DOCUMENT: {
        activeTab: 2,
        apiKey: 'documentForm',
    },
    COURIER: {
        activeTab: 3,
        apiKey: 'originalDocument',
    }
}
